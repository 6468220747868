import React from "react";
import classNames from "classnames";

import { Icon, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { tooltipDelay } from "./helpers";
import { IComment } from "@store/comments/types";

import s from "./CommentCardHeader.module.less";

import { EyeOffIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IHiddenCommentIcon {
  comment: IComment;
}

export default function HiddenCommentIcon({ comment }: IHiddenCommentIcon) {
  if (!comment.is_hidden) return null;

  return (
    <Tooltip mouseEnterDelay={tooltipDelay} title={comment?.tooltips?.hidden}>
      <Icon
        icon={EyeOffIcon}
        className={classNames(s.bbHiddenCommentIcon, {
          [s.bbHiddenCommentIconWithTooltip]: !!comment?.tooltips?.hidden,
        })}
      />
    </Tooltip>
  );
}
