import { action } from "typesafe-actions";
import {
  GET_CUSTOM_TAGS,
  GET_CUSTOM_TAGS_SUCCESS,
  GET_CUSTOM_TAGS_FAILURE,
  POST_CUSTOM_TAG,
  POST_CUSTOM_TAG_SUCCESS,
  POST_CUSTOM_TAG_FAILURE,
  DELETE_CUSTOM_TAG,
  DELETE_CUSTOM_TAG_SUCCESS,
  DELETE_CUSTOM_TAG_FAILURE,
  DELETE_CUSTOM_TAG_FROM_COMMENT,
  DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS,
  DELETE_CUSTOM_TAG_FROM_COMMENT_FAILURE,
  DELETE_CUSTOM_TAG_FROM_REPLY,
} from "./actionTypes";
import { IPostCustomTagPayload, IDeleteCommentCustomTagsPayload } from "./types";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";

export const getCustomTagsAction = () => action(GET_CUSTOM_TAGS);
export const getCustomTagsSuccessAction = (data: { items: ICustomTag[] }) =>
  action(GET_CUSTOM_TAGS_SUCCESS, data);
export const getCustomTagsFailureAction = () => action(GET_CUSTOM_TAGS_FAILURE);

export const postCustomTagAction = (data: IPostCustomTagPayload) => action(POST_CUSTOM_TAG, data);
export const postCustomTagSuccessAction = (
  data: ICustomTag,
  commentId: string,
  parentCommentId?: string,
) => action(POST_CUSTOM_TAG_SUCCESS, { tag: data, commentId, parentCommentId });
export const postCustomTagFailureAction = (commentId?: string) =>
  action(POST_CUSTOM_TAG_FAILURE, commentId);

export const deleteCustomTagAction = (id: string) => action(DELETE_CUSTOM_TAG, id);
export const deleteCustomTagSuccessAction = (id: string) => action(DELETE_CUSTOM_TAG_SUCCESS, id);
export const deleteCustomTagFailureAction = () => action(DELETE_CUSTOM_TAG_FAILURE);

export const deleteCustomTagFromCommentAction = (payload: IDeleteCommentCustomTagsPayload) =>
  action(DELETE_CUSTOM_TAG_FROM_COMMENT, payload);
export const deleteCustomTagFromCommentSuccessAction = (payload: IDeleteCommentCustomTagsPayload) =>
  action(DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS, payload);
export const deleteCustomTagFromCommentFailureAction = () =>
  action(DELETE_CUSTOM_TAG_FROM_COMMENT_FAILURE);

export const deleteCustomTagFromReplyAction = (id: string | null) =>
  action(DELETE_CUSTOM_TAG_FROM_REPLY, id);
