import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, SimpleSelectSingle, SimpleSelectOptionType } from "@bbdevcrew/bb_ui_kit_fe";

import ColorDot from "@components/_common/ColorDot";

import { predefinedFiltersSelector } from "@store/savedFilters/selectors";

import s from "./SavedFilterSelect.module.less";

import { ISavedFilterSelectProps } from "./SavedFilterSelect.types";

export const SavedFilterSelect: React.FC<ISavedFilterSelectProps> = ({
  value,
  onChange,
  children: Trigger,
  onShowAll,
}) => {
  const { t } = useTranslation();
  const selectRef = useRef<Partial<{ close: () => void }>>(null);

  const [query, setQuery] = useState("");
  const predefinedFilters = useSelector(predefinedFiltersSelector);

  const allFiltersOption = useMemo<SimpleSelectOptionType>(
    () => ({
      label: t("pages:dashboard:modals:savesSearch:filterSelector:allConversations"),
      value: "null",
      icon: <ColorDot color="all" />,
    }),
    [t],
  );

  const options: SimpleSelectOptionType[] = useMemo(() => {
    const savedFilterGroups = predefinedFilters.filter(
      ({ id }) => !["stickyFilters", "moderationGroups"].includes(id),
    );

    return [
      allFiltersOption,
      ...savedFilterGroups.reduce<SimpleSelectOptionType[]>(
        (result, { label, items }) => [
          ...result,
          {
            group: label,
          },
          ...items
            .filter(({ name }) => name.trim().toLowerCase().includes(query.trim().toLowerCase()))
            .map(({ id, name, color }) => ({
              label: name,
              value: String(id),
              icon: color ? <ColorDot color={color} /> : undefined,
            })),
        ],
        [],
      ),
    ];
  }, [query, predefinedFilters, allFiltersOption]);

  return (
    <SimpleSelectSingle
      ref={selectRef}
      value={value}
      onChange={newValue => {
        onChange(newValue as string);
      }}
      searchInputPlaceholder={t("generic:search")}
      options={options}
      optionsLoading={false}
      allowSearch
      showResultsBeforeSearch
      onSearch={newQuery => setQuery(String(newQuery))}
      trigger={Trigger}
      className={s.bbSavedFilterSelector}
      footer={
        <div className={s.bbSavedFilterSelectorFooter}>
          <Button
            onClick={() => {
              onShowAll?.();
              selectRef.current?.close?.();
            }}
            _type="secondary"
            _size="sm"
            className={s.bbSavedFilterSelectorFooterButton}
          >
            {t("pages:dashboard:modals:savesSearch:filterSelector:viewAll")}
          </Button>
        </div>
      }
    />
  );
};
