import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { InboxCommentsHeader } from "@components/care/inbox/inboxCommentsHeader/InboxCommentsHeader"; // eslint-disable-line max-len
import { CommentsGridContainer } from "@containers/Comments/Comments";
import EmptyState from "@components/care/inbox/emptyState/EmptyState";
import StreamsSidebar from "../StreamsSidebar";

import { ReplyPlatformType } from "@store/comments/types";
import { filterDataSelector } from "@store/filters/selectors";
import { getAllModulesSectionsSelector } from "@store/modules/selectors";
import { getCareCpsStreamsSelector } from "@store/streams/selectors";
import { postCommentsSearchAction } from "@store/comments/actions";
import { assignToMeAction } from "@store/assign/actions";

import { getInboxParentTabValue, useInboxOptions } from "@components/care/inbox/config";
import { DEFAULT_CONVERSATION_LIST } from "./CareCPSInbox.helpers";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CareCPSInbox.module.less";

import { IInboxStats } from "@store/inboxStats/types";
import { IGetInboxRepliesPayload, IPostCommentBody } from "@store/comments/types";
import { ICareCPSStream } from "@store/streams/types";
import { AssignToMeClickHandlerType } from "@components/care/inbox/inboxCommentsHeader/types";
import { AssignListType } from "@store/assign/types";

export const CareCPSInbox: React.FC<{ stream?: ICareCPSStream }> = ({ stream }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { options: inboxOptions } = useInboxOptions();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [sortValue, setSortValue] = useState<string>("desc");
  const [replyPlatformType, setReplyPlatformType] = useState<ReplyPlatformType>("all");

  const filters = useSelector(filterDataSelector);
  const modulesSections = useSelector(getAllModulesSectionsSelector);
  const streams = useSelector(getCareCpsStreamsSelector);

  const sections = useMemo(() => modulesSections?.care_cps?.[0]?.features || [], [modulesSections]);
  const inboxTab = (searchParams.get("section") as keyof IInboxStats) || DEFAULT_CONVERSATION_LIST;
  const conversationListId = `reply_streams:${stream?.id}:${inboxTab}`;

  const getInboxCount = useCallback(
    (sectionId: string) => {
      return (
        sections
          .find(section => section.id === sectionId)
          ?.counts?.find(count => count.id === "total")?.count || 0
      );
    },
    [sections],
  );

  const inboxStats = useMemo(() => {
    return {
      review: getInboxCount("review"),
      assigned_to_me: getInboxCount("assigned_to_me"),
    };
  }, [getInboxCount]);

  const assignToMeHandler: AssignToMeClickHandlerType = useCallback(
    (assign_from_tab, closeModal) => {
      dispatch(
        assignToMeAction(assign_from_tab as AssignListType, filters, conversationListId, () => {
          addToast({
            type: "success_accent",
            title: t("components:assignToMe:successMessage"),
          });
          if (inboxTab !== "assigned_to_me") {
            setSearchParams({
              ...Object.fromEntries(new URLSearchParams(searchParams.toString())),
              section: "assigned_to_me",
            });
          }
          closeModal();
        }),
      );
    },
    [dispatch, inboxTab, conversationListId, setSearchParams, searchParams, filters, t],
  );

  const fetchConversationList = useCallback(
    (body: IPostCommentBody | IGetInboxRepliesPayload) => {
      const sortVals = body.sort?.split("|");

      return postCommentsSearchAction({
        ...body,
        sort: sortVals && sortVals[0],
        sort_field: sortVals && sortVals[1],
        conversation_list_id: conversationListId,
      });
    },
    [conversationListId],
  );

  if (!stream) {
    return <StreamsSidebar />;
  }

  return (
    <>
      {!!(stream && streams && streams.length) && <StreamsSidebar stream={stream} />}
      <InboxCommentsHeader
        sortValue={sortValue}
        statTabValue={inboxTab}
        onClickAssignToMe={assignToMeHandler}
        onSortChange={val => setSortValue(val)}
        stats={inboxStats}
        statTabParent={getInboxParentTabValue(inboxOptions, inboxTab)}
        replyPlatformTypeValue={replyPlatformType}
        onReplyPlatformChange={setReplyPlatformType}
      />
      {filters && (
        <div className={s.bbCareCPSInboxComments} data-cy="care-cps-streams">
          <CommentsGridContainer
            showStarAction
            showCancelAction
            showHideAction
            showDeleteAction
            showLikeAction
            showDetailsAction
            showLinkedInReactionAction
            showArchiveAction
            withPostPreview
            showFiltersAboveComments
            filters={filters}
            sortValue={sortValue}
            providedFetchAction={fetchConversationList}
            listId={conversationListId}
            replyPlatformType={inboxTab === "replied" ? replyPlatformType : undefined}
            EmptyState={
              <EmptyState tab={inboxTab} stats={inboxStats} onAssignClick={assignToMeHandler} />
            }
          />
        </div>
      )}
    </>
  );
};
