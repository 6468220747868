import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import Keywords from "../../_common/AppFilter/filters/Keywords/Keywords";
import { debounce, InputText, RadioGroup } from "@bbdevcrew/bb_ui_kit_fe";
import { CustomSelect } from "../../_common/AppFilter/filters/CustomSelect";
import KeywordsDropdownBasic from "../../_common/AppFilter/filters/Keywords/KeywordsDropdownBasic";

import { compareTypesSelector } from "@store/me/selectors";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./CompareProjectForm.module.less";

import { ICompareProject } from "@store/compareProjects/types";
import { IAutocompleteOption } from "@store/autocomplete/types";

interface ICompareProjectFormProps {
  form: FormInstance<ICompareProject>;
}

const CompareProjectForm: FC<ICompareProjectFormProps> = ({ form }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const compareTypeT = "components:comparesPanel:projectForm:compareType";

  const [compareType, setCompareType] = useState<string>();

  const compareTypeOptions = useSelector(compareTypesSelector);
  const compareValueOptions = useSelector(autocompleteOptionsSelector);

  const fetchCompareValues = useCallback(
    (query?: string) => {
      if (form.getFieldValue("compare_type") !== "keyword") {
        dispatch(
          getAutocompleteOptionsAction({
            field: form.getFieldValue("compare_type"),
            query: query || "",
          }),
        );
      }
    },
    [dispatch, form],
  );

  const onFormValuesChange = (changedValues: ICompareProject) => {
    if (changedValues.compare_type) {
      form.resetFields(["compare_values"]);

      // We don't need to fetch any options for keywords
      if (changedValues.compare_type !== "keyword") {
        fetchCompareValues();
      }
    }
  };

  const onCompareTypeChange = (value: string) => {
    setCompareType(value);
  };

  const onCompareValuesSearch = debounce((searchTerm: string) => {
    fetchCompareValues(searchTerm);
  }, 300);

  useEffect(() => {
    fetchCompareValues();
  }, [fetchCompareValues]);

  return (
    <Form
      form={form}
      size="small"
      colon={false}
      layout="vertical"
      className={s.bbCompareProjectForm}
      onValuesChange={onFormValuesChange}
    >
      <Form.Item
        name="name"
        label={t("components:comparesPanel:projectForm:projectName:label")}
        rules={[
          {
            required: true,
            message: t("components:comparesPanel:projectForm:projectName:requiredMsg"),
          },
          {
            max: 60,
            message: t("components:comparesPanel:projectForm:projectName:maxCharMsg"),
          },
        ]}
      >
        <InputText
          size="large"
          placeholder={t("components:comparesPanel:projectForm:projectName:placeholder")}
        />
      </Form.Item>
      <Form.Item name="compare_type" label={t(`${compareTypeT}:label`)}>
        <RadioGroup value={compareType} onChange={onCompareTypeChange}>
          {(compareTypeOptions || []).map(({ id, label }) => (
            <RadioGroup.Button _size="sm" key={id} value={id} data-cy={id}>
              {label}
            </RadioGroup.Button>
          ))}
        </RadioGroup>
      </Form.Item>
      <Form.Item shouldUpdate>
        {(formInst: FormInstance<ICompareProject>) => {
          // Using `shouldUpdate` in Form.Item to dynamically update tagCount/Placeholder
          const fieldValue = formInst.getFieldValue("compare_values");
          const compareTypeVal = formInst.getFieldValue("compare_type");

          return (
            <Form.Item
              name="compare_values"
              rules={[
                {
                  required: true,
                  message: t(`${compareTypeT}:reqMsg`),
                },
                {
                  max: 10,
                  type: "array",
                  message: t(`${compareTypeT}:maxLenMsg`),
                },
              ]}
            >
              {compareTypeVal === "keyword" ? (
                <Keywords
                  menuClassName={s.bbKeywordsMenu}
                  showTranslationSwitch={false}
                  DropdownVariation={KeywordsDropdownBasic}
                  customprops={{
                    form: formInst,
                    onFocus: () => {}, // eslint-disable-line
                    name: "compare_values",
                  }}
                />
              ) : (
                <CustomSelect
                  showArrow
                  allowClear
                  isMultiPaste
                  size="large"
                  mode="multiple"
                  filterOption={false}
                  onSearch={onCompareValuesSearch}
                  maxTagCount={fieldValue && fieldValue.length < 2 ? 2 : 0}
                  placeholder={t(`${compareTypeT}:placeholder`)}
                  maxTagPlaceholder={t(`components:filters:selectedTag`, {
                    number: fieldValue && fieldValue.length,
                  })}
                  customprops={{
                    name: "compare_values",
                    form: formInst,
                    onFocus: () => {}, // eslint-disable-line
                  }}
                  options={
                    (compareValueOptions[
                      compareTypeVal as keyof typeof compareValueOptions
                    ] as (IAutocompleteOption & { id: string })[]) || []
                  }
                />
              )}
            </Form.Item>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default CompareProjectForm;
