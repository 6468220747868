export const GET_MODERATION_STREAMS = "MODERATE/GET_MODERATION_STREAMS";
export const GET_MODERATION_STREAMS_SUCCESS = "MODERATE/GET_MODERATION_STREAMS_SUCCESS";
export const GET_MODERATION_STREAMS_FAILURE = "MODERATE/GET_MODERATION_STREAMS_FAILURE";

export const GET_MODERATION_STREAMS_COMMENTS = "MODERATE/GET_MODERATION_STREAMS_COMMENTS";
export const GET_MODERATION_STREAMS_COMMENTS_SUCCESS =
  "MODERATE/GET_MODERATION_STREAMS_COMMENTS_SUCCESS";
export const GET_MODERATION_STREAMS_COMMENTS_FAILURE =
  "MODERATE/GET_MODERATION_STREAMS_COMMENTS_FAILURE";

export const GET_CARE_CPS_STREAMS = "CARE_CPS/GET_CARE_CPS_STREAMS";
export const GET_CARE_CPS_STREAMS_SUCCESS = "CARE_CPS/GET_CARE_CPS_STREAMS_SUCCESS";
export const GET_CARE_CPS_STREAMS_FAILURE = "CARE_CPS/GET_CARE_CPS_STREAMS_FAILURE";
export const RESET_CARE_CPS_STREAMS = "CARE_CPS/RESET_CARE_CPS_STREAMS";
export const SET_CARE_CPS_STREAM = "CARE_CPS/SET_CARE_CPS_STREAM";
