import dayjs from "dayjs";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Button, InputWhite, SelectStyled, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import CSVDownloadInfo from "@components/_common/CSVDownloadInfo/CSVDownloadInfo";

import {
  getCommentsCSVSelector,
  getCommentsCSVSelectorPending,
  getCommentsCSVSelectorSuccessful,
} from "@store/comments/selectors";
import { filterDataSelector } from "@store/filters/selectors";
import { getCommentsCSVAction } from "@store/comments/actions";

import s from "./ConversationListHeader.module.less";

import { useYTSelected } from "@utils/useYTselected";
import { getDefaultSortOptions } from "@utils/comments";
import { ICommentsCSVPayload } from "@store/comments/types";

import { DownloadIcon } from "@assets/index";

interface IConversationListHeaderProps {
  sortValue: string;
  onSortChange: (value: string) => void;
}

const DEFAULT_COMMENT_EXPORT_LIMIT = 5000;

export const ConversationListHeader: FC<IConversationListHeaderProps> = ({
  sortValue,
  onSortChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const downloadCSVDefaultTooltip = t("components:comments:download:downloadTooltip", {
    limit: DEFAULT_COMMENT_EXPORT_LIMIT,
  });

  const [commentsDownloadModalOpen, setCommentsDownloadModalOpen] = useState(false);

  const filters = useSelector(filterDataSelector);
  const commentsCSVFetched = useSelector(getCommentsCSVSelectorSuccessful);
  const commentsCSVPending = useSelector(getCommentsCSVSelectorPending);
  const commentsCSV = useSelector(getCommentsCSVSelector);

  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);

  const getCommentsCSV = useCallback(
    (data: ICommentsCSVPayload) => dispatch(getCommentsCSVAction(data)),
    [dispatch],
  );

  useEffect(() => {
    if (commentsCSV && commentsCSVFetched) {
      const now = dayjs();
      fileDownload(
        commentsCSV,
        `comments_${now.format("YYYY-MM-DD")}_${now.format("hh_mm_A")}.csv`,
      );
    }
  }, [commentsCSV, commentsCSVFetched]);

  const triggerCommentsDownload = () => {
    setCommentsDownloadModalOpen(true);

    getCommentsCSV({
      filters,
      limit: DEFAULT_COMMENT_EXPORT_LIMIT,
      sort: sortValue && sortValue === "asc" ? "asc" : "desc",
    });
  };

  return (
    <div className={s.bbConversationListHeader}>
      <h5 className={s.bbConversationListHeaderTitle}>
        <span className={s.bbConversationListHeaderTitlePrefix}>
          {t("components:listen:conversationList:breadcrumbs:parentTitle")}
        </span>{" "}
        {t("components:listen:conversationList:breadcrumbs:childTitle")}
      </h5>
      <div className={s.bbConversationListHeaderFilterWrapper}>
        <SelectStyled
          _size="sm"
          value={sortValue}
          onChange={onSortChange}
          InputVariation={InputWhite}
          options={getDefaultSortOptions()}
          wrapperClassName={s.bbConversationListHeaderSortSelect}
        />
        <Tooltip
          placement="top"
          title={isYTFilterSelected ? t("pages:insights:ytNoDownload") : downloadCSVDefaultTooltip}
        >
          <span>
            <Button
              disabled={isYTFilterSelected}
              className={s.bbDownloadCommentsCSV}
              onClick={() => !isYTFilterSelected && triggerCommentsDownload()}
            >
              <DownloadIcon />
            </Button>
          </span>
        </Tooltip>
      </div>
      <CSVDownloadInfo
        loading={commentsCSVPending}
        open={commentsDownloadModalOpen}
        csvDownloaded={commentsCSVFetched}
        downloadAgain={triggerCommentsDownload}
        closeModal={() => setCommentsDownloadModalOpen(false)}
      />
    </div>
  );
};
