import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { Avatar } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ListeningChip from "../ListeningChip/ListeningChip";
import { Tooltip, Loading } from "@bbdevcrew/bb_ui_kit_fe";
import PostTagsList from "../Social/Post/PostTags/PostTagsList";
import PostTagsDropdown from "../Social/Post/PostTags/PostTagsDropdown";
import { PostImageOverlayPortal } from "../CommentDetail/PostImageOverlay";

import { deleteTagFromPostAction } from "@store/postTags/actions";
import { clearPostAction, getPostAction } from "@store/posts/actions";
import { postSelector, getPostProcessingSelector } from "@store/posts/selectors";

import s from "./PostView.module.less";

import { extractDate } from "@utils/dates";
import { camelize } from "@utils/strings";
import { getCountriesFlags } from "@utils/countries";
import { isEarnedMessage } from "../CommentsGrid/helpers";
import { getMediaTypeIcon } from "../CommentDetail/helpers";
import { IPostPreview } from "../CommentDetail/CommentDetail.type";
import { MAX_SHOW_FLAG_COUNT } from "../CommentDetail/CommentDetailsConfig";
import { getPlatformIconBasicColor, isYoutubePlatform } from "@utils/platform";
import { PostPlacementEnum } from "../Social/Post/Post.type";

const MAX_TAG_LIST_LENGTH = 2;

const PostView: FC<IPostPreview> = ({
  post,
  post_id,
  type = "inline",
  imageSize = 72,
  isClickable = false,
  showPostTags = false,
  onPostOverlayClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const postWrapperRef = useRef<HTMLDivElement>(null);

  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayTopPosition, setOverlayTopPosition] = useState<number>();

  const _post = useSelector(postSelector);
  const isPostLoading = useSelector(getPostProcessingSelector);
  const postData = post || _post;

  const isYTPost = postData?.platform_type.id
    ? isYoutubePlatform(postData?.platform_type.id)
    : false;

  const fetchPost = useCallback(id => dispatch(getPostAction(id)), [dispatch]);
  const deleteTagFromPost = useCallback(
    tagId =>
      postData?.id &&
      dispatch(deleteTagFromPostAction({ post_tag_id: tagId, post_id: postData?.id })),
    [dispatch, postData?.id],
  );

  useEffect(() => {
    dispatch(clearPostAction());

    if (post_id && !post) {
      fetchPost(post_id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Set PostImageOverlay position
    if (overlayTopPosition === undefined) {
      const rowEl = postWrapperRef.current?.closest<HTMLDivElement>(".ant-row");
      setOverlayTopPosition(rowEl?.offsetTop);
    }
    // eslint-disable-next-line
  }, [postWrapperRef.current, overlayTopPosition]);

  const onPostImageEnter = () => {
    if (!showOverlay) {
      setShowOverlay(true);
    }
  };
  const onPostImageLeave = () => {
    if (showOverlay) {
      setShowOverlay(false);
    }
  };

  const onClickPostImage = () => {
    if (isClickable) {
      postData && window.open(postData.permalink, "_blank");
    }
  };

  const renderTags = () => {
    return (
      <div className={s.bbPostViewTagsWrapper} data-cy="post-tags">
        {type === "inline" && post && isEarnedMessage(post) && (
          <ListeningChip
            className={s.bbPostViewListeningChip}
            iconClassName={s.bbPostViewListeningChipIcon}
            wrapperClassName={s.bbPostViewListeningChipWrapper}
          />
        )}
        {postData?.post_category_type && (
          <Tooltip
            placement="top"
            title={t(
              `components:posts:table:tableHeader:tooltips:tags:${
                postData?.post_category_type?.id ? camelize(postData.post_category_type.id) : ""
              }`,
            )}
          >
            <span className={classNames(s.bbPostTag, s.bbPostTagClickable)}>
              {postData?.post_category_type.label}
            </span>
          </Tooltip>
        )}
        {postData?.post_media_type && (
          <span className={s.bbPostTag}>
            <span className={s.bbPostMediaType}>
              {getMediaTypeIcon(postData?.post_media_type?.id)}
            </span>
            <span>{postData?.post_media_type?.label}</span>
          </span>
        )}
        {postData?.targetings?.length ? (
          <span className={classNames(s.bbPostTag, s.bbPostTagClickable)}>
            {t("tags:targetings")} {getCountriesFlags(postData?.targetings, MAX_SHOW_FLAG_COUNT)}
          </span>
        ) : null}
        {showPostTags && (
          <>
            {!!postData?.post_tags.length && (
              <PostTagsList
                postTags={postData?.post_tags}
                onDeleteTag={deleteTagFromPost}
                maxTagListLength={MAX_TAG_LIST_LENGTH}
                tagClassName={s.bbPostTagsListTag}
                tagIconClassName={s.bbPostTagsListIcon}
              />
            )}
            <div className={s.bbPostTagsDropdownBtnWrapper}>
              <PostTagsDropdown postTags={postData?.post_tags} postId={postData?.id} />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {!!postData && !isPostLoading && (
        <div>
          {type === "block" && (
            <div className={s.bbPostViewTitleWrapper}>
              <span className={s.bbPostViewTitle}>
                {t("components:comments:details:postPreview")}
              </span>
              {post && isEarnedMessage(post) && (
                <ListeningChip
                  className={s.bbPostViewListeningChip}
                  iconClassName={s.bbPostViewListeningChipIcon}
                  wrapperClassName={s.bbPostViewListeningChipWrapper}
                />
              )}
            </div>
          )}

          <div className={s.bbPostViewWrapper} data-cy="post-wrapper">
            <div
              ref={postWrapperRef}
              onClick={onClickPostImage}
              onMouseEnter={onPostImageEnter}
              onMouseLeave={onPostImageLeave}
            >
              <Tooltip title={isClickable ? t("components:comments:actions:showPost") : ""}>
                <Avatar
                  size={imageSize}
                  shape="square"
                  style={{ fontSize: "12px" }}
                  src={postData?.picture || ""}
                  className={classNames(s.bbPostViewImagePreview, {
                    [s.bbPortraitImage]: postData?.post_placement === PostPlacementEnum.STORY,
                    [s.bbPostViewImagePreviewClickable]: isClickable,
                  })}
                >
                  {t("generic:noImage")}
                </Avatar>
              </Tooltip>
              {showOverlay && !!onPostOverlayClick && (
                <PostImageOverlayPortal
                  imgUrl={postData.picture}
                  onClick={onPostOverlayClick}
                  style={{ top: overlayTopPosition }}
                  isImgPortrait={postData?.post_placement === PostPlacementEnum.STORY}
                />
              )}
            </div>

            {type === "inline" && (
              <div
                data-cy="post-right-section"
                className={classNames(s.bbPostRightSection, s.bbPostNoWrap)}
              >
                <div className={s.bbPostRightSectionMain}>
                  <div
                    data-cy="post-platform"
                    className={classNames({
                      [s.bbPlatformIconLarge]: isYTPost,
                      [s.bbPlatformIconSmall]: !isYTPost,
                    })}
                  >
                    {postData?.platform_type.id &&
                      getPlatformIconBasicColor(postData.platform_type.id)}
                  </div>
                  <div className={s.bbPostNoWrap}>
                    <div className={s.bbPostNoWrap}>
                      <span className={s.bbPostViewInlineAuthor}>{postData?.author_name}</span>
                      <span className={s.bbPostViewInlineDate}>
                        {extractDate(postData?.created_time_pretty)}
                      </span>
                    </div>
                    <div data-cy="post-text" className={classNames(s.bbPostText, s.bbPostNoWrap)}>
                      {postData?.message}
                    </div>
                  </div>
                </div>

                {renderTags()}
              </div>
            )}

            {type === "block" && (
              <div data-cy="post-right-section">
                <div
                  data-cy="post-platform"
                  className={classNames({
                    [s.bbPlatformIconLarge]: isYTPost,
                    [s.bbPlatformIconSmall]: !isYTPost,
                  })}
                >
                  {postData?.platform_type.id &&
                    getPlatformIconBasicColor(postData.platform_type.id)}
                </div>
                <div className={s.bbPostAuthorName}>{postData?.author_name}</div>
                <div className={s.bbPostAuthorDate}>
                  {extractDate(postData?.created_time_pretty)}
                </div>
                <div data-cy="post-text" className={s.bbPostMessage}>
                  {postData?.message}
                </div>
              </div>
            )}
          </div>
          {type === "block" && <div className={s.bbPostTagsListing}>{renderTags()}</div>}
        </div>
      )}
      {isPostLoading && (
        <div className={s.bbPostViewloading}>
          <Loading
            isLoading
            type="spinning"
            tip={t("pages:loading:spinText")}
            indicator={<LoadingOutlined />}
          />
        </div>
      )}
    </>
  );
};

export default PostView;
