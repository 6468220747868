import React from "react";
import classNames from "classnames/bind";

import s from "./Accordion.module.less";

import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IAccordionProps {
  title: string;
  collapsed: boolean;
  body: React.ReactNode;
  headerPrefix: React.ReactNode;
  topPrefix?: React.ReactNode;
  headerSuffix?: React.ReactNode;
  bottomSuffix?: React.ReactNode;
  type?: "grey" | "white";
  setCollapsed: () => void;
}

const Accordion: React.FC<IAccordionProps> = ({
  title,
  body,
  topPrefix,
  bottomSuffix,
  headerPrefix,
  headerSuffix,
  collapsed,
  setCollapsed,
  type = "grey",
}) => (
  <div className={classNames(s.bbAccordion, s[`bbAccordion-${type}`])}>
    {topPrefix}
    <div className={s.bbAccordionHeader} onClick={setCollapsed}>
      <div className={s.bbAccordionHeaderLeft}>
        {headerPrefix}
        <span>{title}</span>
      </div>
      <div className={s.bbAccordionHeaderRight}>
        {headerSuffix}
        {collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
      </div>
    </div>
    {bottomSuffix}
    {!collapsed && <div className={s.bbAccordionBody}>{body}</div>}
  </div>
);

export default Accordion;
