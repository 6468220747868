import { IPost } from "../Social/Post/Post.type";

export const isEarnedMessage = (post: IPost) => {
  return (
    post?.type === "CAPTION_MENTION" ||
    post?.type === "COMMENT_MENTION" ||
    post?.type === "HASHTAG_MENTION" ||
    post?.type === "QUOTE_MENTION" ||
    post?.type === "MENTION" ||
    post?.type === "REVIEW"
  );
};
