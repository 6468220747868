import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useCallback, useEffect, ChangeEvent, MouseEvent } from "react";

import {
  Icon,
  Button,
  Tooltip,
  Loading,
  InputWhite,
  Dropdown,
  Divider,
} from "@bbdevcrew/bb_ui_kit_fe";

import {
  customTagsSelector,
  fetchingCustomTagsSelector,
  postCustomTagSuccessfulSelector,
} from "@store/customTags/selectors";
import {
  deleteCustomTagAction,
  getCustomTagsAction,
  postCustomTagAction,
} from "@store/customTags/actions";

import { IComment } from "@store/comments/types";
import { getHighlightedText } from "@bbdevcrew/bb_ui_kit_fe";
import { IPostCustomTagPayload } from "@store/customTags/types";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";

import s from "./BatchMode.module.less";

import { PlusIcon, TagIcon } from "@assets/index";
import { CloseIcon, DeleteIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ITagSelectionButtonProps {
  className?: string;
  selectedComments: IComment[];
  dropdownTags: ICustomTag[];
  setDropdownTags: (tags: ICustomTag[]) => void;
  selectedTags: ICustomTag[];
  setSelectedTags: (tags: ICustomTag[]) => void;
  isCustomTagDropdownOpen: boolean;
  setCustomTagDropdownOpen: (open: boolean) => void;
}

export default function TagSelectionDropdown({
  isCustomTagDropdownOpen,
  setCustomTagDropdownOpen,
  className,
  selectedComments,
  dropdownTags,
  selectedTags,
  setSelectedTags,
  setDropdownTags,
}: ITagSelectionButtonProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");
  const [existingTagEntered, setExistingTagEntered] = useState(false);

  const initialCustomTags = useSelector(customTagsSelector);
  const customTagsFetching = useSelector(fetchingCustomTagsSelector);
  const isCustomTagPostSucceded = useSelector(postCustomTagSuccessfulSelector);

  const fetchCustomTags = useCallback(() => dispatch(getCustomTagsAction()), [dispatch]);

  const getUniqueDropdownTags = () => {
    return initialCustomTags.filter(
      tag => !selectedTags?.map(_tag => _tag.label).includes(tag.label),
    );
  };

  useEffect(() => {
    setDropdownTags(getUniqueDropdownTags());
    // eslint-disable-next-line
  }, [selectedComments, customTagsFetching, selectedTags, initialCustomTags]);

  useEffect(() => {
    if (isCustomTagDropdownOpen) fetchCustomTags();
    else {
      setInputValue("");
      setExistingTagEntered(false);
    }
  }, [isCustomTagDropdownOpen, fetchCustomTags]);

  useEffect(() => {
    if (isCustomTagPostSucceded) fetchCustomTags();
  }, [isCustomTagPostSucceded, fetchCustomTags]);

  const postCustomTag = useCallback(
    (data: IPostCustomTagPayload) => dispatch(postCustomTagAction(data)),
    [dispatch],
  );

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const deleteCustomTagFromDropdown = useCallback(
    (id: string) => dispatch(deleteCustomTagAction(id)),
    [dispatch],
  );

  const onTagBtnClick = () => {
    setCustomTagDropdownOpen(!isCustomTagDropdownOpen);
  };

  const tagExists = (tagLabel: string) => {
    return selectedTags?.some(selectedTag => selectedTag.label === tagLabel);
  };

  const onSelectTag = (tag: ICustomTag) => {
    if (!tagExists(tag.label)) {
      setSelectedTags([...selectedTags, tag]);
      setInputValue("");
    }
  };

  const onAddNewTag = () => {
    if (!tagExists(inputValue)) {
      postCustomTag({
        label: inputValue,
      });
      setSelectedTags([...selectedTags, { label: inputValue }]);
      setInputValue("");
    }
  };

  const onRemoveCustomTag = (e: MouseEvent, tag: ICustomTag) => {
    e.stopPropagation();
    tag.id && deleteCustomTagFromDropdown(tag.id);
    setDropdownTags(dropdownTags.filter(_tag => _tag.id !== tag.id));
  };

  const onRemoveSelectedTag = (tagLabel: string) => {
    const filteredTags = [...selectedTags?.filter(tag => tag.label !== tagLabel)];
    setSelectedTags(filteredTags);
  };

  const tagsDropdownListing = (
    <div className={s.bbTagsDropdownListing}>
      <div className={s.bbTagsDropdownListingTitle}>
        {t("components:customTags:chooseFromExisting")}
      </div>

      <div>
        <div className={s.bbTagsDropdownListingTags}>
          {dropdownTags
            ?.filter(dt => dt.label.includes(inputValue))
            ?.map(tag => (
              <div
                key={tag.id}
                onClick={() => onSelectTag(tag)}
                className={s.bbTagsDropdownListingTag}
              >
                {inputValue ? getHighlightedText(tag.label, inputValue) : tag.label}
                <Button
                  _size="sm"
                  onClick={e => onRemoveCustomTag(e, tag)}
                  className={s.bbTagsDropdownListingTagRemoveBtn}
                >
                  <DeleteIcon />
                </Button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Dropdown
        placement="topLeft"
        open={isCustomTagDropdownOpen}
        dropdownClassName={s.bbTagsDropdown}
        menuClassName={s.bbTagsDropdownMenu}
        setOpen={() => setCustomTagDropdownOpen(!isCustomTagDropdownOpen)}
        trigger={
          <Button _size="sm" className={className} onClick={onTagBtnClick}>
            <TagIcon />
            <span>{t("containers:batchMode:actionBar:tag")}</span>{" "}
            {!!selectedTags?.length && (
              <span className={s.bbTagsDropdownSelectedTagsBadge}>{selectedTags?.length}</span>
            )}
          </Button>
        }
      >
        <div
          id="custom-tags-trigger"
          data-cy="custom-tags-trigger"
          className={s.bbTagsDropdownSelectedTagsWrapper}
        >
          <div id="selected-tags" className={s.bbTagsDropdownSelectedTags}>
            {!!selectedTags?.length && (
              <div className={s.bbTagsDropdownSelectedTagsTitle}>
                {t("containers:batchMode:tag:selectedTags")}
              </div>
            )}
            {!!selectedTags?.length && (
              <div className={s.bbTagsDropdownSelectedTagsListing}>
                {selectedTags?.map((tag, index) => {
                  return (
                    <div
                      className={s.bbTagsDropdownSelectedTagsTagWrapper}
                      key={(tag.id || tag.label) + index}
                    >
                      <span className={s.bbTagsDropdownSelectedTagsTag}>{tag.label}</span>
                      <Icon
                        _size="sm"
                        icon={CloseIcon}
                        onClick={() => onRemoveSelectedTag(tag.label)}
                        className={s.bbTagsDropdownSelectedTagsTagIcon}
                      />
                    </div>
                  );
                })}
                <Divider />
              </div>
            )}
          </div>

          <div className={s.bbTagsDropdownAddTitle}>{t("components:customTags:addTitle")}</div>

          <InputWhite
            autoFocus
            _size="sm"
            value={inputValue}
            id="custom-tags-input"
            onChange={onInputChange}
            data-testid="custom-tags-input"
            wrapperClassName={s.bbTagsInput}
            placeholder={t("components:customTags:placeholder")}
          />

          <Tooltip title={existingTagEntered ? t("components:customTags:tagAlreadyExists") : null}>
            <div>
              <Button
                _size="sm"
                _type="primary"
                onClick={onAddNewTag}
                className={s.bbAddTagBtn}
                disabled={!inputValue.trim() || existingTagEntered}
              >
                <span className={s.bbAddTagBtnIcon}>
                  <PlusIcon />
                </span>
                {t("components:customTags:dropdownAddBtn")}
              </Button>
            </div>
          </Tooltip>

          {customTagsFetching ? (
            <div className={s.bbTagsLoading}>
              <Loading type="spinning" isLoading />
            </div>
          ) : dropdownTags?.length ? (
            tagsDropdownListing
          ) : null}
        </div>
      </Dropdown>
    </>
  );
}
