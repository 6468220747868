import { of } from "rxjs";
import i18next from "i18next";
import { AjaxError } from "rxjs/ajax";
import { interceptors } from "./interceptor";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { IAPIError, getAPIErrorMessages } from "./getAPIErrorMessages";

// eslint-disable-next-line
export const handleError = (apiError: AjaxError, callback: Function, type?: string) => {
  if (apiError.response) {
    const { statusCode } = apiError.response as IAPIError;
    const { title, message } = getAPIErrorMessages(apiError.response);

    interceptors(statusCode, type);
    addToast({
      type: "danger_accent",
      title,
      message,
    });

    return of(callback(apiError));
  }

  if (apiError.name === "TimeoutError") {
    addToast({
      type: "danger_accent",
      title: apiError.name,
      message: apiError.message,
    });

    return of(callback(apiError));
  }

  addToast({
    type: "danger_accent",
    title: i18next.t("generic:error"),
    message: i18next.t("generic:unexpectedError"),
  });

  return of({ type: "UNKNOWN_ERROR" });
};
