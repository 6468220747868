import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setDraftReplyAction } from "@store/replies/actions";
import { getDraftReplySelector } from "@store/replies/selectors";

export type UseDraftReplyReturnType = [string, (message: string) => void];

export const useDraftReply = (commentId: string): UseDraftReplyReturnType => {
  const dispatch = useDispatch();
  const draftReply = useSelector(getDraftReplySelector(commentId));
  const [localDraftReply, setLocalDraftReply] = useState(draftReply);

  useEffect(() => {
    setLocalDraftReply(draftReply);
  }, [draftReply]);

  const setDraftReply = useCallback(
    message => {
      setLocalDraftReply(message);
      dispatch(setDraftReplyAction(commentId, message));
    },
    [dispatch, commentId],
  );

  return [localDraftReply, setDraftReply];
};
