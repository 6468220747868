import cn from "classnames/bind";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "antd";
import { TermsConditions } from "../termsConditions/TermsConditions";
import { Card, Button, InputText, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { validateEmailAction } from "@store/user/actions";
import { tiktokSignupAction } from "@store/tiktokApp/actions";
import { tiktokSigningUpSelector, getTiktokSignupTokenSelector } from "@store/tiktokApp/selectors";

import s from "./TTSignup.module.less";
import footerStyle from "../signupSteps/footer/Footer.module.less";

import { ITTFormStep1 } from "./TTSignup.types";
import { ITiktokSignupPayload } from "@store/tiktokApp/types";

const cx = cn.bind(footerStyle);

export const TTSignupStep1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<ITTFormStep1>();

  const tiktokSignupToken = useSelector(getTiktokSignupTokenSelector);
  const tiktokSigningUp = useSelector(tiktokSigningUpSelector);

  const signupTiktok = useCallback(
    (payload: ITiktokSignupPayload) => dispatch(tiktokSignupAction(payload)),
    [dispatch],
  );

  const onInternalFinish = (values: ITTFormStep1) => {
    signupTiktok({
      email: values.email,
      signup_token: tiktokSignupToken,
    });
  };

  const onEmailBlur = () => {
    const emailVal = form.getFieldValue("email");

    if (emailVal) dispatch(validateEmailAction({ field: "email", value: emailVal }));
  };

  return (
    <div className={s.bbTTSignupStep1Wrapper}>
      <div className={s.bbTTSignupStep1}>
        <div className={s.bbTTSignupStep1Title}>{t("components:ttAppCenter:step1:title")}</div>
        <div className={s.bbTTSignupStep1Subtitle}>
          {t("components:ttAppCenter:step1:subTitle")}
        </div>
        <Card className={s.bbTTSignupStep1Card}>
          <Form
            form={form}
            id="ttStep1Form"
            layout="vertical"
            requiredMark={false}
            data-rewardful={true}
            onFinish={onInternalFinish}
          >
            <Form.Item
              name="email"
              validateTrigger="onBlur"
              label={t("components:ttAppCenter:step1:emailLabel")}
              rules={[
                {
                  required: true,
                  message: t("pages:signup:step1:left:form:email:required"),
                },
                {
                  type: "email",
                  message: t("pages:signup:step1:left:form:email:invalidEmail"),
                },
              ]}
              className={s.bbTTSignupStep1Email}
            >
              <InputText
                size="large"
                type="email"
                name="email"
                onBlur={onEmailBlur}
                autoComplete="new-email"
                round
              />
            </Form.Item>
          </Form>
          <TermsConditions label={t("components:ttAppCenter:step1:termsAndConditions")} />
          <div className={s.bbTTSignupStep1Disclaimer}>
            {t("components:ttAppCenter:step1:disclaimer")}
          </div>
        </Card>
      </div>
      <div className={cx(footerStyle.bbStepFooter, footerStyle.bbStepFooterTT)}>
        <Button
          _size="md"
          _type="primary"
          form="ttStep1Form"
          type="submit"
          loading={tiktokSigningUp}
          disabled={tiktokSigningUp}
        >
          {t("generic:continue")}
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};
