import dayjs from "dayjs";
import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { FC, PropsWithChildren } from "react";

import { TooltipProps } from "antd";
import { Chip, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import SentimentBar from "../SentimentBar/SentimentBar";

import s from "./PostPreviewTooltip.module.less";

import { IPost } from "../Social/Post/Post.type";
import { getPlatformIconBasicColor } from "@utils/platform";
import { getPostMediaTypeIcon, getPostMediaTypeLabel } from "./helpers";

const cx = cn.bind(s);

const PostPreviewTooltipBody = ({ post }: { post: IPost }) => {
  const { t } = useTranslation();
  const day = dayjs(post.created_time_pretty).format("DD MMM");
  const year = dayjs(post.created_time_pretty).format("YYYY");
  const time = dayjs(post.created_time_pretty).format("hh:mm A");

  const getAttachmentPreview = () => {
    const labels = (
      <>
        <div className={s.bbMediaType}>
          <div className={s.bbIcon}>{getPostMediaTypeIcon(post)}</div>
          <div className={s.bbLabel}>{getPostMediaTypeLabel(post)}</div>
        </div>
        <div className={s.bbType}>{post.post_category_type?.label}</div>
      </>
    );

    if (post.picture) {
      return (
        <div className={s.bbPreview}>
          <img src={post.picture} className={s.bbPicture} />
          {labels}
        </div>
      );
    }

    if (post.attachments.length && post.attachments[0].type === "video") {
      return (
        <div className={s.bbPreview}>
          <video className={s.bbVideo}>
            <source src={post.attachments[0].url} />
          </video>
          {labels}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={s.bbPostPreviewTooltipBody}>
      <div className={s.bbHeader}>
        <div>
          <div className={s.bbTitle}>
            <div className={cx(s.bbPlatform, `bbPlatform-${post.platform_type.id}`)}>
              {getPlatformIconBasicColor(post.platform_type.id)}
            </div>
            <div className={s.bbAuthor}>{post.author_name}</div>
          </div>
          <div className={s.bbSubtitle}>{`${day}, ${year} ${t("generic:at")} ${time}`}</div>
        </div>
        <Chip _size="xxs" text={t("components:postPreview:stats")} _type="grey" />
      </div>
      <div className={s.bbMessage}>{post.message}</div>
      {getAttachmentPreview()}
      <div className={s.bbPostIdWrapper}>
        {t("components:postPreview:postId")}
        <span className={s.bbPostId}>{post.id}</span>
      </div>
      {!!post.comment_metrics?.total_comments && <SentimentBar data={post.comment_metrics} />}
    </div>
  );
};

interface IPostPreviewTooltipProps extends Pick<TooltipProps, "placement"> {
  post: IPost;
}

export const PostPreviewTooltip: FC<PropsWithChildren<IPostPreviewTooltipProps>> = ({
  children,
  post,
  placement = "top",
}) => {
  return (
    <Tooltip
      placement={placement}
      color="white"
      title={<PostPreviewTooltipBody post={post} />}
      overlayInnerStyle={{
        width: 370,
      }}
    >
      {children}
    </Tooltip>
  );
};
