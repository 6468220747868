import React, { useMemo } from "react";

import { Icon, Input } from "@bbdevcrew/bb_ui_kit_fe";
import SelectedExcludedIndicator from "./SelectedExcludedIndicator";

import s from "./ClearableTrigger.module.less";

import { ITriggerProps } from "@bbdevcrew/bb_ui_kit_fe";
import { generateInputValueState, generateInputValueStateSingle } from "./helpers";

import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ISelectTrigger extends ITriggerProps {
  excludedTooltip?: React.ReactNode;
}

export function SelectTrigger({
  id,
  _size,
  value,
  isOpen,
  disabled,
  excludedValues,
  wrapperClassName,
  onClick,
  tooltip,
  suffixEl,
  InputVariation = Input,
  showDefaultPlaceholder,
  placeholder,
  excludedTooltip,
  ...props
}: ISelectTrigger) {
  const { all, selected, excluded } = useMemo(() => {
    return typeof value === "string"
      ? generateInputValueStateSingle(value)
      : generateInputValueState(value, excludedValues, placeholder, showDefaultPlaceholder);
    // eslint-disable-next-line
  }, [value, excludedValues]);

  const _onClick = () => {
    if (!disabled) onClick();
  };

  return (
    <div id="trigger" data-cy="trigger" onClick={_onClick} className={s.bbSelectTrigger}>
      <InputVariation
        id={id}
        value={all}
        _size={_size}
        wrapperClassName={wrapperClassName}
        suffixEl={
          suffixEl ? (
            suffixEl
          ) : (
            <Icon _size={_size} icon={isOpen ? ChevronUpIcon : ChevronDownIcon} />
          )
        }
        placeholder={showDefaultPlaceholder || (!selected && !excluded) ? placeholder : undefined}
        {...props}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
      />

      {(!!selected || !!excluded) && (
        <SelectedExcludedIndicator
          selected={selected}
          excluded={excluded}
          excludedTooltip={excludedTooltip}
          tooltip={tooltip}
        />
      )}
    </div>
  );
}

export default SelectTrigger;
