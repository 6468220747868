import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useState, useCallback } from "react";

import { Input, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";

import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import {
  debounce,
  useSearch,
  IObjectOption,
  handleMultiselectState,
} from "@bbdevcrew/bb_ui_kit_fe";
import { useTagState } from "../common";
import { getTransformedAssetOptionsMulti } from "./helpers";
import { IMultiAssetsFilterProps, IAutocompleteAsset } from "./Assets.type";
import { IGetAutocompleteOptionsPayload } from "@store/autocomplete/types";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

import s from "./Assets.module.less";

const AssetsFilterMulti: FC<IMultiAssetsFilterProps> = ({
  value,
  onChange,
  disabled,
  placeholder,
  customprops,
  fetchFilters,
  dropdownPlacement,
  getAutoCompleteOptions,
  dropdownMatchSelectWidth,
  showDefaultPlaceholder,
  InputVariation = Input,
  TriggerVariation,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedLabels, setSelectedLabels] = useState<string[]>(value || []);
  const [options, setOptions] = useState<IObjectOption[]>([]);

  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  const { tagFilterValues, _onChange, _onClearValues } = useTagState(options, customprops, value);

  const {
    options: searchedOptions,
    SearchInput,
    searchTerm,
    setSearchTerm,
  } = useSearch(options, t("generic:search"));

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const fetchValues = (term: string) => {
    getAutocompleteOptions({
      field: "asset",
      query: term,
      filter: fetchFilters,
    });
  };

  useEffect(() => {
    fetchValues(searchTerm);
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    const transformedOptions = getTransformedAssetOptionsMulti(
      autocompleteOptions.asset,
      props?.additionalLabelFields,
    );

    setOptions(transformedOptions as IObjectOption[]);
    getAutoCompleteOptions?.(autocompleteOptions.asset as IAutocompleteAsset[]);
    // eslint-disable-next-line
  }, [autocompleteOptions.asset]);

  useEffect(() => {
    setSelectedLabels(tagFilterValues);
    // eslint-disable-next-line
  }, [tagFilterValues]);

  const _onClear = () => {
    _onClearValues();
    onChange?.([]);
  };

  const onInternalChange = (changedValue: string) => {
    _onChange(changedValue);

    const updatedOptionIds = handleMultiselectState(options, tagFilterValues, changedValue);

    onChange?.(updatedOptionIds);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (TriggerVariation) {
      return TriggerVariation;
    }

    const tooltipElement = selectedLabels.map(label => (
      <span key={label} className={s.bbDisplayBlock}>
        {options?.find(option => option.id === label)?.label || label}
      </span>
    ));

    const triggerProps = {
      onClear: _onClear,
      showDefaultPlaceholder,
      ...tooltipProps,
    };

    if (!selectedLabels.length) {
      return ClearableTrigger(triggerProps);
    }

    return ClearableTrigger({
      tooltip: tooltipElement,
      ...triggerProps,
    });
  };

  return (
    <div data-cy="app-filter-asset_ids" data-stonly-target="app-global__filters--asset_ids">
      <SelectStyledMulti
        _size="sm"
        id="asset_ids"
        disabled={disabled}
        value={tagFilterValues}
        options={searchedOptions}
        placeholder={placeholder}
        highlightTerm={searchTerm}
        onChange={onInternalChange}
        DropdownHeader={SearchInput}
        InputVariation={InputVariation}
        TriggerVariation={TriggerWithTooltip}
        dropdownPlacement={dropdownPlacement}
        onDropdownClose={() => setSearchTerm("")}
        excludePlaceholder={t("generic:exclude")}
        excludedPlaceholder={t("generic:excluded")}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        data-cy={props["data-cy" as keyof typeof props]}
        className={s.bbAssetsMultiSelect}
      />
    </div>
  );
};

export default AssetsFilterMulti;
