import React, { FC } from "react";

import { Tooltip, AreaChart, defaultAreaProps } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CardStat.module.less";

import {
  areas,
  formatter,
  XAxisProps,
  YAxisProps,
  responsiveProps,
  calculatePreviousValue,
  calculatePreviousValuePercent,
} from "./helpers";
import { ICardStatProps } from "./CardStat.type";

const CardStat: FC<ICardStatProps> = ({
  data,
  name,
  value,
  color,
  lineXName,
  widthInfo,
  chartProps,
  tooltipDelay,
  previousValue,
  timelineTooltipProps,
  percentageTooltipProps,
  leftContentTooltipProps,
}) => {
  return (
    <div className={s.bbCardStat}>
      <div className={s.bbCardStatContent}>
        <Tooltip
          placement="top"
          mouseEnterDelay={tooltipDelay || 0.3}
          title={leftContentTooltipProps?.title}
          overlayInnerStyle={leftContentTooltipProps?.style}
        >
          <div style={{ width: widthInfo || 154 }} className={s.bbCardStatLeftContainer}>
            <div className={s.bbCardStatValue} style={{ color: color }}>
              {formatter(value)}
            </div>
            <div className={s.bbCardStatName} style={{ color: color }}>
              {name}
            </div>
          </div>
        </Tooltip>

        {data ? (
          <Tooltip
            placement="top"
            mouseEnterDelay={tooltipDelay || 0.3}
            title={timelineTooltipProps?.title}
            overlayInnerStyle={timelineTooltipProps?.style}
          >
            <div className={s.bbCardChartContainer}>
              <AreaChart
                {...chartProps}
                data={data}
                responsive
                referenceLine
                areas={areas}
                XAxisProps={XAxisProps}
                YAxisProps={YAxisProps}
                areaProps={defaultAreaProps}
                responsiveProps={responsiveProps}
                referenceLineProps={{
                  stroke: "#8c8c8c",
                  strokeDasharray: "3",
                  x: lineXName || data[data.length / 2].name,
                }}
              />
            </div>
          </Tooltip>
        ) : null}

        {typeof previousValue !== "undefined" ? (
          <div className={s.bbCardStatRightContainer}>
            <div className={s.bbCardStatPreviousValueContainer}>
              <div className={s.bbCardStatPreviousValue} style={{ color: color }}>
                {calculatePreviousValue(previousValue, value)}
              </div>
              <Tooltip
                placement="top"
                mouseEnterDelay={tooltipDelay || 0.3}
                title={percentageTooltipProps?.title}
                overlayInnerStyle={percentageTooltipProps?.style}
              >
                <div className={s.bbCardStatPreviousValuePercent} style={{ background: color }}>
                  {calculatePreviousValuePercent(previousValue, value)}
                </div>
              </Tooltip>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CardStat;
