import React from "react";
import dayjs from "dayjs";
import { i18n } from "i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CommentCardHeader.module.less";
import { getI18next } from "../../../../languages/i18next";

const i18nextinstance = getI18next() as i18n;

export const tooltipDelay = 0.2;

export const formatTime = (date: string) => dayjs(date).format("hh:mm A");

const displayMultiTooltip = (items: string[]) => {
  return (
    <>
      {items.map(item => (
        <div key={item}>{item}</div>
      ))}
    </>
  );
};

export const getCommentSourceInfo = (comment_phrase: string) => {
  const regex = /\{{(.*?)\}}/g;
  let match;
  let lastIndex = 0;
  const parts = [];

  while ((match = regex.exec(comment_phrase)) !== null) {
    const matchedText = match[1].trim();
    let replacement;

    // Check if the matched text is an array
    const arrayRegex = /^\["#\w+"(?:,"#\w+")*\]$/;
    const arrayMatch = matchedText.match(arrayRegex);

    if (arrayMatch) {
      const items = JSON.parse(arrayMatch[0]);
      const itemCount = items.length;
      replacement = (
        <Tooltip key={match.index} title={displayMultiTooltip(items)}>
          <strong className={s.bbCommentPhraseTooltip}>
            {itemCount} {i18nextinstance.t("generic:more").toLowerCase()}
          </strong>
        </Tooltip>
      );
    } else {
      replacement = <strong key={match.index}>{matchedText}</strong>;
    }

    parts.push(<span key={lastIndex}>{comment_phrase.substring(lastIndex, match.index)}</span>);
    parts.push(replacement);
    lastIndex = match.index + match[0].length;
  }

  parts.push(<span key={lastIndex}>{comment_phrase.substring(lastIndex)}</span>);

  return <>{parts}</>;
};
