import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, FC, useCallback, useState } from "react";

import { List } from "antd";
import { CardHeader } from "./CardHeader";
import { Card, Alert, Tooltip, InputWhiteBordered, SearchIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CardList.module.less";

import { CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { ICardListProps } from "./CardList.type";

const cx = cn.bind(s);

export const CardList: FC<ICardListProps> = ({
  alert,
  platformType,
  selectedAssets,
  onAssetSelect,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  // Check if all assignable assets are in `selectedAssets`
  const areAllAssetsAdded = useCallback(() => {
    const allAssignable = platformType.assets.filter(({ status }) => status === "assignable");
    const thisPlatSelected = selectedAssets?.filter(
      ({ asset_platform }) => asset_platform === platformType.id,
    );

    return allAssignable.length === thisPlatSelected?.length;
  }, [platformType, selectedAssets]);

  const isAssetSelected = useCallback(
    (assetId: string) => !!selectedAssets?.find(({ id }) => id === assetId),
    [selectedAssets],
  );

  // Returns either searched or all assets
  const getAssetData = useCallback(
    () =>
      searchTerm
        ? platformType.assets.filter(
            ({ id, name }) =>
              id.toLowerCase().includes(searchTerm.toLowerCase()) ||
              name.toLowerCase().includes(searchTerm.toLowerCase()),
          )
        : platformType.assets,
    [searchTerm, platformType.assets],
  );

  const onSelectAllToggle = () => {
    if (onAssetSelect) {
      if (areAllAssetsAdded()) {
        return onAssetSelect(
          "deselect",
          platformType.assets.filter(({ status }) => status === "assignable"),
        );
      }

      const nonSelectedAssets = platformType.assets
        .filter(({ status }) => status === "assignable")
        .filter(({ id }) => !isAssetSelected(id));

      return onAssetSelect("select", nonSelectedAssets);
    }
  };

  return (
    <Card className={s.bbAssetCard}>
      <CardHeader
        id={platformType.id}
        title={platformType.label}
        allSelected={areAllAssetsAdded()}
        onSelectAllToggle={onSelectAllToggle}
      />
      {alert && (
        <Alert
          align="left"
          type={alert.type}
          message={alert.message}
          className={s.bbAssetCardAlert}
        />
      )}
      <InputWhiteBordered
        _size="sm"
        id="asset-search"
        wrapperClassName={s.bbAssetCardSearch}
        placeholder={t("components:assetTabs:list:searchPlaceholder")}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
        prefixEl={
          <span className={s.bbSearchIcon}>
            <SearchIcon />
          </span>
        }
      />
      <List
        dataSource={getAssetData()}
        className={s.bbAssetCardList}
        renderItem={asset => (
          <List.Item
            className={cx(s.bbAssetCardListItem, {
              [s.bbAssetCardListItemDisabled]: asset.status === "added",
              [s.bbAssetCardListItemSelected]:
                asset.status === "added" || isAssetSelected(asset.id),
            })}
            onClick={() =>
              onAssetSelect &&
              asset.status === "assignable" &&
              onAssetSelect(isAssetSelected(asset.id) ? "deselect" : "select", [asset])
            }
          >
            <Tooltip
              className={s.bbAssetCardListItemTooltip}
              title={asset.status === "added" && t("components:assetTabs:list:addedTooltip")}
            >
              <div className={s.bbAssetCardListItemContent}>
                <span className={s.bbAssetCardListItemLabel}>{asset.name}</span>
                <small className={s.bbAssetCardListItemDescription}>
                  {asset.description || asset.id}
                </small>
              </div>
              {(asset.status === "added" || isAssetSelected(asset.id)) && <CheckIcon />}
            </Tooltip>
          </List.Item>
        )}
      />
    </Card>
  );
};
