import { isTiktokApp } from "./isTiktokApp";

const contentType = "application/json";

// eslint-disable-next-line
export const getHeaders = (data: { [key: string]: any }) => {
  return {
    ...data,
    "Content-Frame": isTiktokApp() ? "iframe" : "",
    "Content-Type": contentType,
  };
};
