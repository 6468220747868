import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Form } from "antd";
import { Help, Input, RadioGroup } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SavedReplyForm.module.less";

import { platforms, UsernameOptionType } from "./SavedReplyForm.helpers";
import { SavedReplyNameMaxLimit } from "../helper";

interface ISavedReplyBlock {
  usernameOption: UsernameOptionType;
  onUsernameOptionChange: (value: string) => void;
}

const TOOLTIP_WIDTH = 287;

const UsernameSettings: React.FC<ISavedReplyBlock> = ({
  usernameOption,
  onUsernameOptionChange,
}) => {
  const { t } = useTranslation();

  const UsernameDescriptionTooltipContent = () => {
    return (
      <div className={s.bbPostMetricTooltip}>
        {platforms.map(platform => (
          <div key={platform} className={s.bbPostMetricTooltipBlock}>
            <Trans
              i18nKey={
                "components:reply:bbSavedReplies:create:usernameSettings:descriptionTooltip:" +
                platform
              }
              components={{
                strong: <strong />,
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={s.bbUsernameSettings}>
      <div className={s.bbUsernameSettingsDescription}>
        <span>{t("components:reply:bbSavedReplies:create:usernameSettings:description")}</span>
        <span>
          <Help
            tooltipProps={{
              title: UsernameDescriptionTooltipContent(),
              style: { width: TOOLTIP_WIDTH },
            }}
          />
        </span>
      </div>
      <div className={s.bbUsernameSettingsUnknownTitle}>
        <span>{t("components:reply:bbSavedReplies:create:usernameSettings:usernameTitle")}</span>
        <span>
          <Help
            tooltipProps={{
              title: t(
                "components:reply:bbSavedReplies:create:usernameSettings:unknownUsernameTitle",
              ),
              style: { width: TOOLTIP_WIDTH },
            }}
          />
        </span>
      </div>
      <RadioGroup
        value={usernameOption}
        onChange={onUsernameOptionChange}
        className={s.bbUsernameSettingsUnknownRadio}
      >
        <RadioGroup.Button _size="sm" key="skip" value="skip">
          {t("components:reply:bbSavedReplies:create:usernameSettings:skip")}
        </RadioGroup.Button>
        <RadioGroup.Button _size="sm" key="use_word" value="use_word">
          {t("components:reply:bbSavedReplies:create:usernameSettings:useWord")}
        </RadioGroup.Button>
      </RadioGroup>
      {usernameOption === "use_word" && (
        <Form.Item
          name="username_placeholder_word"
          rules={[
            {
              required: true,
              message: t("components:reply:bbSavedReplies:create:usernameSettings:wordInputError"),
            },
            {
              max: SavedReplyNameMaxLimit,
              message: t("components:reply:bbSavedReplies:create:variationCharLimit", {
                amount: SavedReplyNameMaxLimit,
              }),
            },
            {
              // If contains only whitespaces
              pattern: /^(?!\s*$).+/,
              validateTrigger: "onSubmit",
              message: t("components:reply:bbSavedReplies:create:usernameSettings:wordInputError"),
            },
          ]}
        >
          <Input
            _size="sm"
            placeholder={t(
              "components:reply:bbSavedReplies:create:usernameSettings:wordInputPlaceholder",
            )}
            wrapperClassName={s.bbUsernameSettingsWordInput}
          />
        </Form.Item>
      )}
    </div>
  );
};

export default UsernameSettings;
