import {
  CLEAR_TICKET_STATE,
  CREATE_INTEGRATION,
  CREATE_INTEGRATION_FAILURE,
  CREATE_INTEGRATION_SUCCESS,
  CREATE_TICKET,
  CREATE_TICKET_FAILURE,
  CREATE_TICKET_SUCCESS,
  DELETE_INTEGRATION,
  DELETE_INTEGRATION_FAILURE,
  DELETE_INTEGRATION_SUCCESS,
  GET_INTEGRATIONS_LIST,
  GET_INTEGRATIONS_LIST_FAILURE,
  GET_INTEGRATIONS_LIST_SUCCESS,
  UPDATE_INTEGRATION,
  UPDATE_INTEGRATION_FAILURE,
  UPDATE_INTEGRATION_SUCCESS,
} from "./actionTypes";
import { createAction, createCustomAction } from "typesafe-actions";
import {
  ICreateIntegrationPayload,
  ICreateTicketPayload,
  IDeleteIntegrationPayload,
  IIntegrationsListResponse,
  Integration,
} from "./types";

// Integrations
export const getIntegrationsListAction = createAction(GET_INTEGRATIONS_LIST)();
export const getIntegrationsListSuccessAction = createAction(
  GET_INTEGRATIONS_LIST_SUCCESS,
)<IIntegrationsListResponse>();
export const getIntegrationsListFailureAction = createAction(GET_INTEGRATIONS_LIST_FAILURE)<void>();

export const createIntegrationAction =
  createAction(CREATE_INTEGRATION)<ICreateIntegrationPayload>();
export const createIntegrationSuccessAction = createCustomAction(CREATE_INTEGRATION_SUCCESS);
export const createIntegrationFailureAction = createAction(CREATE_INTEGRATION_FAILURE)<string>();

export const updateIntegrationAction = createAction(UPDATE_INTEGRATION)<Integration>();
export const updateIntegrationSuccessAction = createCustomAction(UPDATE_INTEGRATION_SUCCESS);
export const updateIntegrationFailureAction = createAction(UPDATE_INTEGRATION_FAILURE)<string>();

export const deleteIntegrationAction =
  createAction(DELETE_INTEGRATION)<IDeleteIntegrationPayload>();
export const deleteIntegrationSuccessAction = createCustomAction(DELETE_INTEGRATION_SUCCESS);
export const deleteIntegrationFailureAction = createAction(DELETE_INTEGRATION_FAILURE)<string>();

// Tickets
export const createTicketAction = createAction(CREATE_TICKET)<ICreateTicketPayload>();
export const createTicketSuccessAction = createCustomAction(CREATE_TICKET_SUCCESS);
export const createTicketFailureAction = createAction(CREATE_TICKET_FAILURE)<string>();
export const clearTicketStateAction = createCustomAction(CLEAR_TICKET_STATE);
