import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import AppSidebarPortal from "@components/_common/AppSidebar/AppSidebarPortal";
import { StreamSelector } from "../CareCPSInbox/StreamSelector";
import StreamsModal from "../StreamsModal";

import s from "./StreamsSidebar.module.less";

import { useAppSidebar } from "@components/_common/AppSidebar/helpers";
import { IStreamsSidebarProps } from "./StreamsSidebar.types";
import { ModalViewType } from "../StreamsModal/StreamsModal.types";

import { PlusIcon } from "@assets/index";

export const StreamsSidebar: React.FC<IStreamsSidebarProps> = ({ stream }) => {
  const { t } = useTranslation();
  const { isCollapsed } = useAppSidebar();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalView, setModalView] = useState<ModalViewType>();

  return (
    <>
      {!!stream && (
        <AppSidebarPortal
          placement="section-streams"
          component={() => (
            <StreamSelector
              stream={stream}
              onClick={() => {
                setModalView("list");
                setIsModalOpen(true);
              }}
              active={isModalOpen && modalView === "list"}
              className={s.bbStreamSelector}
            />
          )}
        />
      )}
      <AppSidebarPortal
        placement={stream ? "section-streams-action" : "top"}
        component={() =>
          isCollapsed ? null : (
            <Tooltip
              title={t("components:careCps:streamsModal:createTooltip")}
              className={s.bbSidebarActionTooltip}
            >
              <>
                <Button
                  className={classNames(s.bbSidebarAction, {
                    [s.bbSidebarActionEmpty]: !stream,
                  })}
                  _type="secondary"
                  onClick={() => {
                    setModalView("form");
                    setIsModalOpen(true);
                  }}
                >
                  <PlusIcon />
                  {!stream && t("components:careCps:streamsModal:addTitle")}
                </Button>
              </>
            </Tooltip>
          )
        }
      />
      <StreamsModal
        open={isModalOpen}
        view={modalView}
        setView={setModalView}
        close={() => setIsModalOpen(false)}
      />
    </>
  );
};
