import React, { FC } from "react";
import cn from "classnames/bind";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { Tooltip, SelectableTag } from "@bbdevcrew/bb_ui_kit_fe";

import { IInjectedCustomProps } from "../../Filters.type";
import { YT_AVAILABLE_DAYS } from "../FilterRange/FilterRange.type";
import { getPlatformIconBasicColor, platformIconName } from "@utils/platform";

import s from "./PlatformType.module.less";

const cx = cn.bind(s);

interface IPlatformTypeProps extends IInjectedCustomProps {
  options: { label: string; value: string }[];
  isYTEnabled?: boolean;
  onChange?: (value: string[]) => void;
}

export const PlatformType: FC<IPlatformTypeProps> = ({
  customprops: { form },
  options,
  isYTEnabled,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleChange = (platformType: string, checked: boolean) => {
    const selectedTags = (form?.getFieldValue("platform_types") || []) as string[];
    const nextSelectedTags = checked
      ? [...selectedTags, platformType]
      : selectedTags.filter(type => type !== platformType);
    onChange?.(nextSelectedTags);
  };

  const ytUnvailableDateRangeSelected = () => {
    const selectedRangeOption: string = form?.getFieldValue("data_range_option");
    const customDates: Dayjs[] = form?.getFieldValue("dates");
    const ytAvailableDateRange = dayjs().subtract(YT_AVAILABLE_DAYS, "days").format("YYYY-MM-DD");

    return (
      selectedRangeOption === "last_12_months" ||
      selectedRangeOption === "" ||
      (selectedRangeOption === "custom" &&
        !!customDates?.length &&
        customDates[0].isBefore(ytAvailableDateRange))
    );
  };

  return (
    <div className={s.bbPlatformTypesWrapper}>
      {options.map(({ value, label }) => {
        const isChecked = (form?.getFieldValue("platform_types") || []).includes(value);
        const ytUnavailableRangeSelected =
          value === "youtube" && !isYTEnabled && ytUnvailableDateRangeSelected();
        const tooltipTitle = ytUnavailableRangeSelected
          ? t("components:filters:dates:ytNoAnalysisForDates")
          : label;

        return (
          <Tooltip title={tooltipTitle} key={value}>
            <span className={s.bbPlatformTypeTagWrapper}>
              <SelectableTag
                checked={isChecked}
                defaultSelected={isChecked}
                disabled={ytUnavailableRangeSelected}
                onChange={checked => handleChange(value, checked)}
                data-cy={`app-filter-platform_types-${value}`}
                className={cx(s.bbPlatformType, s[`bbPlatformType-${value}`])}
                data-stonly-target={`app-global__filters--platform_types--${value}`}
              >
                {getPlatformIconBasicColor(platformIconName(value))}
              </SelectableTag>
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
};
