import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import { SelectOld, Tooltip, RangePicker } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import s from "./FilterRange.module.less";

import { dateList } from "@utils/dates";
import { useYTSelected } from "@utils/useYTselected";
import { rangeTimeformat, rangeDateformat } from "@utils/filters";
import { IFilterRangeProps, YT_AVAILABLE_DAYS, MIN_DATE_RANGE } from "./FilterRange.type";

import { CalendarIcon } from "@assets/index";

const FilterRange: FC<IFilterRangeProps> = (props: IFilterRangeProps) => {
  const { form } = props.customprops;
  const { t } = useTranslation();

  const _isYTSelected = useYTSelected(form.getFieldValue("platform_types") || []);

  const isYTSelected = props.isYTSelected !== undefined ? props.isYTSelected : _isYTSelected;

  const [open, setOpen] = useState(false);
  const [dataRangeOptions, setDataRangeOptions] = useState(dateList(t, isYTSelected));

  const me = useSelector(meSelector);

  const _onChangeDataRangeOption = (value: string | undefined) => {
    if (value === undefined || value === "") {
      form?.resetFields(["dates"]);
      props.onChangeDataRangeOption?.("");
      return isYTSelected
        ? form?.setFieldsValue({ data_range_option: "last_30_days" })
        : form?.setFieldsValue({ data_range_option: "" });
    }

    if (value !== "custom") {
      form?.resetFields(["dates"]);
    }

    props.onChange?.(value || "");
    props.onChangeDataRangeOption?.(value);
  };

  const onDropdownChange = (visible: boolean) => {
    setOpen(visible);
    setDataRangeOptions(dateList(t, isYTSelected));
  };

  const isDateDisabled = (d: Dayjs) => {
    const datesUnavailable = !d || d.isBefore(MIN_DATE_RANGE);

    if (isYTSelected) {
      const ytAvailableDateRange = dayjs().subtract(YT_AVAILABLE_DAYS, "days").format("YYYY-MM-DD");
      return datesUnavailable || d.isBefore(ytAvailableDateRange);
    }

    return datesUnavailable;
  };

  const renderCustomDate = (d: Dayjs) => {
    const dateIsDisabled = isDateDisabled(d);

    return (
      <div>
        <Tooltip title={dateIsDisabled ? t("components:filters:dates:ytNoAnalysis") : ""}>
          <div className="ant-calendar-date ant-picker-cell-inner">{d.date()}</div>
        </Tooltip>
      </div>
    );
  };

  // eslint-disable-next-line
  const { dropdownPlacement, ...rest } = props;

  return (
    <>
      <SelectOld
        {...rest}
        noBorder
        allowClear={!props.isYTSelected}
        open={open}
        dropdownMatchSelectWidth
        options={dataRangeOptions}
        onChange={_onChangeDataRangeOption}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        className={s.bbDataRangeSelect}
        popupClassName={s.bbDataRangeOptionDropdown}
        onDropdownVisibleChange={visible => onDropdownChange(visible)}
        suffixIcon={<CalendarIcon />}
        placeholder={t("components:filters:comments:items:dataRangeOptions:all_time")}
      />
      {form?.getFieldValue("data_range_option") === "custom" ? (
        <Form.Item name="dates" noStyle>
          <RangePicker
            // eslint-disable-next-line
            // @ts-ignore
            picker="date"
            size="large"
            themeLight
            format={rangeDateformat}
            className={s.bbFilterRangePicker}
            onCalendarChange={props.onCalendarChange}
            dateRender={renderCustomDate}
            disabledDate={isDateDisabled}
            showTime={{
              format: rangeTimeformat,
              defaultValue: [dayjs().startOf("day"), dayjs().endOf("day")],
              use12Hours: me?.calendar_12_hour_format,
            }}
            placeholder={[
              t("components:filters:datePlaceholder.0"),
              t("components:filters:datePlaceholder.1"),
            ]}
            // eslint-disable-next-line
            // @ts-ignore
            ranges={{
              [t("components:filters:dates:ranges:today")]: [
                dayjs().startOf("day"),
                dayjs().endOf("day"),
              ],
              [t("components:filters:dates:ranges:thisWeek")]: [
                dayjs().startOf("week"),
                dayjs().endOf("week"),
              ],
              [t("components:filters:dates:ranges:thisMonth")]: [
                dayjs().startOf("month"),
                dayjs().endOf("month"),
              ],
            }}
          />
        </Form.Item>
      ) : null}
    </>
  );
};

export default FilterRange;
