import { createReducer, ActionType } from "typesafe-actions";
import { postDashboardActionSuccess } from "./actions";
import { IDashboardState } from "./types";
import { GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILURE } from "./actionTypes";

const initialState = {
  dashboard: undefined,
  fetchingDashboard: false,
  fetchedDashboard: false,
  fetchedDashboardFail: false,
};

export const dashboardReducer = createReducer<IDashboardState>(initialState, {
  //GET_DASHBOARD
  [GET_DASHBOARD]: (state: IDashboardState) => ({
    ...state,
    fetchingDashboard: true,
    fetchedDashboard: false,
    fetchedDashboardFail: false,
  }),
  [GET_DASHBOARD_SUCCESS]: (
    state: IDashboardState,
    action: ActionType<typeof postDashboardActionSuccess>,
  ) => ({
    ...state,
    dashboard: { ...state.dashboard, ...action.payload },
    fetchingDashboard: false,
    fetchedDashboard: true,
    fetchedDashboardFail: false,
  }),
  [GET_DASHBOARD_FAILURE]: (state: IDashboardState) => ({
    ...state,
    fetchingDashboard: false,
    fetchedDashboard: false,
    fetchedDashboardFail: true,
  }),
});
