import React, { FC } from "react";
import classNames from "classnames";

import { Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { ICommentActionProps } from "./CommentAction.type";

import s from "./CommentAction.module.less";

const tooltipDelay = 0.2;
const tooltipFontSize = 14;

const CommentAction: FC<ICommentActionProps> = ({
  type,
  active,
  onClick,
  loading,
  disabled,
  children,
  tooltipProps,
  className = "",
}) => {
  const actionBtn = (
    <Button
      _size="sm"
      onClick={type === "disabled" ? undefined : onClick}
      loading={loading}
      disabled={disabled}
      className={classNames(s.bbCommentAction, className, s[`bbCommentAction-${type}`], {
        [s.bbCommentActionActive]: active,
      })}
    >
      {children}
    </Button>
  );

  return (
    <Tooltip
      mouseEnterDelay={tooltipDelay}
      title={tooltipProps?.title || ""}
      overlayInnerStyle={{ fontSize: tooltipFontSize, ...tooltipProps?.style }}
    >
      {disabled && tooltipProps?.title ? <span>{actionBtn}</span> : actionBtn}
    </Tooltip>
  );
};

export default CommentAction;
