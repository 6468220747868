import { action, createAction } from "typesafe-actions";
import {
  GET_REPLIES,
  GET_REPLIES_SUCCESS,
  GET_REPLIES_FAILURE,
  GET_THREAD_REPLIES,
  GET_THREAD_REPLIES_SUCCESS,
  GET_THREAD_REPLIES_FAILURE,
  POST_SAVED_REPLIES,
  POST_SAVED_REPLIES_SUCCESS,
  POST_SAVED_REPLIES_FAILURE,
  POST_SAVED_REPLY,
  POST_SAVED_REPLY_SUCCESS,
  POST_SAVED_REPLY_FAILURE,
  PUT_SAVED_REPLY,
  PUT_SAVED_REPLY_SUCCESS,
  PUT_SAVED_REPLY_FAILURE,
  DELETE_SAVED_REPLIES,
  DELETE_SAVED_REPLIES_SUCCESS,
  DELETE_SAVED_REPLIES_FAILURE,
  CLEAR_REPLIES,
  GET_REPLIES_PAGE,
  GET_REPLIES_PAGE_SUCCESS,
  POST_COMMENT_REPLY,
  POST_COMMENT_REPLY_SUCCESS,
  POST_COMMENT_REPLY_FAILURE,
  PATCH_REPLIES_PARENT_SENTIMENT,
  PATCH_REPLIES_ITEM_SENTIMENT,
  PERFORM_REPLIES_ITEMS_ACTION_SUCCESS,
  PERFORM_REPLIES_PARENT_ACTION_SUCCESS,
  DELETE_BRAND_REPLY,
  DELETE_BRAND_REPLY_SUCCESS,
  DELETE_BRAND_REPLY_FAILURE,
  GET_REPLIES_AI_SUGGESTION,
  GET_REPLIES_AI_SUGGESTION_SUCCESS,
  GET_REPLIES_AI_SUGGESTION_FAILURE,
  CLEAR_SAVED_REPLY_ALL_TAGS,
  SET_SAVED_REPLY_BB_TAGS,
  SET_SAVED_REPLY_CUSTOM_TAGS,
  POST_REPLY_BB_TAG,
  DELETE_REPLY_BB_TAG,
  CLEAR_REPLIES_COMMENT_LIST,
  CLEAR_REPLYING_THREAD_COMMENT,
  SET_DRAFT_REPLY,
  UPLOAD_REPLY_IMAGE,
  UPLOAD_REPLY_IMAGE_SUCCESS,
  UPLOAD_REPLY_IMAGE_FAILURE,
  CLEAR_REPLY_IMAGE,
  POST_COMMENT_REPLY_REGION_IS_NOT_ALLOWED_FAILURE,
  GET_ACCOUNT_POSTS,
  GET_ACCOUNT_POSTS_FAILURE,
  GET_ACCOUNT_POSTS_SUCCESS,
  GET_REPLIES_ATTACHMENTS,
  GET_REPLIES_ATTACHMENTS_SUCCESS,
  GET_REPLIES_ATTACHMENTS_FAILURE,
  GET_BB_REPLIES_VARIATIONS,
  GET_BB_REPLIES_VARIATIONS_SUCCESS,
  GET_BB_REPLIES_VARIATIONS_FAILURE,
} from "./actionTypes";
import {
  IMessage,
  IPostReplyPayload,
  IReplyData,
  IReplyPageResponse,
  ISavedReplies,
  IPostsResult,
  ISavedRepliesData,
  ISavedReplyPayload,
  SavedReplyType,
} from "./types";
import { ICommentTag, ICommentActionPayload, IComment } from "../comments/types";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";

export const getRepliesAction = (commentId: string, commentsNavigationId?: string) =>
  action(GET_REPLIES, { commentId, commentsNavigationId });
export const getRepliesSuccessAction = (data: IReplyData) => action(GET_REPLIES_SUCCESS, data);
export const getRepliesFailureAction = () => action(GET_REPLIES_FAILURE);

export const getRepliesAttachmentsAction = () => action(GET_REPLIES_ATTACHMENTS);
export const getRepliesAttachmentsSuccessAction = (data: IReplyData) =>
  action(GET_REPLIES_ATTACHMENTS_SUCCESS, data);
export const getRepliesAttachmentsFailureAction = () => action(GET_REPLIES_ATTACHMENTS_FAILURE);

export const getThreadRepliesAction = (
  privateReplyCommentId: string,
  threadId: string,
  comment?: IComment,
) => action(GET_THREAD_REPLIES, { privateReplyCommentId, threadId, comment });
export const getThreadRepliesSuccessAction = (data: IReplyData) =>
  action(GET_THREAD_REPLIES_SUCCESS, data);
export const getThreadRepliesFailureAction = () => action(GET_THREAD_REPLIES_FAILURE);

export const generateAISuggestedReplyAction = (commentId: string) =>
  action(GET_REPLIES_AI_SUGGESTION, { commentId });
export const generateAISuggestedReplySuccessAction = (text: string) => {
  return action(GET_REPLIES_AI_SUGGESTION_SUCCESS, text);
};
export const generateAISuggestedReplyFailureAction = () =>
  action(GET_REPLIES_AI_SUGGESTION_FAILURE);

export const postSavedRepliesAction = (query?: string, presetQuery?: string) =>
  action(POST_SAVED_REPLIES, { query, presetQuery });
export const postSavedRepliesSuccessAction = (
  data: ISavedReplies,
  query?: string,
  presetQuery?: string,
) => action(POST_SAVED_REPLIES_SUCCESS, { data, query, presetQuery });
export const postSavedRepliesFailureAction = () => action(POST_SAVED_REPLIES_FAILURE);

export const postSavedReplyAction = (data: ISavedReplyPayload) => action(POST_SAVED_REPLY, data);
export const postSavedReplySuccessAction = (type: SavedReplyType, data: ISavedRepliesData) =>
  action(POST_SAVED_REPLY_SUCCESS, { type, data });
export const postSavedReplyFailureAction = () => action(POST_SAVED_REPLY_FAILURE);

export const putSavedReplyAction = (data: ISavedReplyPayload) => action(PUT_SAVED_REPLY, data);
export const putSavedReplySuccessAction = (type: SavedReplyType, data: ISavedRepliesData) =>
  action(PUT_SAVED_REPLY_SUCCESS, { type, data });
export const putSavedReplyFailureAction = () => action(PUT_SAVED_REPLY_FAILURE);

export const deleteSavedRepliesAction = (id: string, type: SavedReplyType) =>
  action(DELETE_SAVED_REPLIES, { id, type });
export const deleteSavedRepliesSuccessAction = (id: string, type: SavedReplyType) =>
  action(DELETE_SAVED_REPLIES_SUCCESS, { id, type });
export const deleteSavedRepliesFailureAction = () => action(DELETE_SAVED_REPLIES_FAILURE);

export const deleteBrandReplyAction = (
  id: string,
  isIgnored?: boolean,
  isAIHighlighted?: boolean,
) => action(DELETE_BRAND_REPLY, { id, isIgnored, isAIHighlighted });
export const deleteBrandReplySuccessAction = (
  id: string,
  isIgnored?: boolean,
  isAIHighlighted?: boolean,
) => action(DELETE_BRAND_REPLY_SUCCESS, { id, isIgnored, isAIHighlighted });
export const deleteBrandReplyFailureAction = () => action(DELETE_BRAND_REPLY_FAILURE);

export const getRepliesPageAction = (cursor: string) => action(GET_REPLIES_PAGE, cursor);
export const getRepliesPageSuccessAction = (data: IReplyPageResponse) =>
  action(GET_REPLIES_PAGE_SUCCESS, data);

export const postCommentReplyAction = (data: IPostReplyPayload) => action(POST_COMMENT_REPLY, data);
export const postCommentReplySuccessAction = (
  data: IMessage,
  commentId: string,
  is_ignored: boolean,
  is_private_reply?: boolean,
) =>
  action(POST_COMMENT_REPLY_SUCCESS, {
    data,
    commentId,
    is_ignored,
    is_private_reply,
  });
export const postCommentReplyFailureAction = () => action(POST_COMMENT_REPLY_FAILURE);
export const postCommentReplyRegionIsNotAllowedFailureAction = () =>
  action(POST_COMMENT_REPLY_REGION_IS_NOT_ALLOWED_FAILURE);

export const patchRepliesParentSentimentAction = (
  id: string,
  sentiment: string,
  data: IReplyData,
) => action(PATCH_REPLIES_PARENT_SENTIMENT, { id, sentiment, data });

export const patchRepliesItemsSentimentAction = (id: string, sentiment: string, data: IReplyData) =>
  action(PATCH_REPLIES_ITEM_SENTIMENT, { id, sentiment, data });
export const performRepliesItemsActionSuccessAction = (
  commentAction: ICommentActionPayload,
  data: IReplyData,
) => action(PERFORM_REPLIES_ITEMS_ACTION_SUCCESS, { commentAction, data });
export const performRepliesParentActionSuccessAction = (
  commentAction: ICommentActionPayload,
  data: IReplyData,
) => action(PERFORM_REPLIES_PARENT_ACTION_SUCCESS, { commentAction, data });

export const postReplyBBTagAction = (tag: ICommentTag) => action(POST_REPLY_BB_TAG, tag);
export const deleteReplyBBTagAction = (tagId: string) => action(DELETE_REPLY_BB_TAG, tagId);
export const setSavedReplyBBTags = (tags: ICommentTag[]) => action(SET_SAVED_REPLY_BB_TAGS, tags);
export const setSavedReplyCustomTags = (tags: ICustomTag[]) =>
  action(SET_SAVED_REPLY_CUSTOM_TAGS, tags);

export const clearSavedReplyAllTagsAction = () => action(CLEAR_SAVED_REPLY_ALL_TAGS);
export const clearRepliesAction = () => action(CLEAR_REPLIES);
export const clearRepliesCommentListAction = (conversation_list_id?: string) =>
  action(CLEAR_REPLIES_COMMENT_LIST, { conversation_list_id });
export const clearReplyingThreadCommentAction = () => action(CLEAR_REPLYING_THREAD_COMMENT);

export const setDraftReplyAction = (commentId: string, message: string) =>
  action(SET_DRAFT_REPLY, { commentId, message });

export const uploadReplyImageAction = (data: File) => action(UPLOAD_REPLY_IMAGE, data);
export const uploadReplyImageSuccessAction = createAction(UPLOAD_REPLY_IMAGE_SUCCESS)<{
  url: string;
  fileName: string;
}>();
export const uploadReplyImageFailureAction = createAction(UPLOAD_REPLY_IMAGE_FAILURE)<string>();
export const clearReplyImageAction = () => action(CLEAR_REPLY_IMAGE);

export const getAccountPostsAction = (params: {
  filters: {
    platform_types: string[];
    asset_ids: string[];
    post_ids?: string[];
  };
  pagination: {
    page: number;
  };
}) => action(GET_ACCOUNT_POSTS, params);
export const getAccountPostsSuccessAction = createAction(GET_ACCOUNT_POSTS_SUCCESS)<IPostsResult>();
export const getAccountPostsFailureAction = createAction(GET_ACCOUNT_POSTS_FAILURE)<string>();

export const getSavedRepliesVariationsAction = (message: string) =>
  action(GET_BB_REPLIES_VARIATIONS, message);
export const getSavedRepliesVariationsSuccessAction = (items: string[]) =>
  action(GET_BB_REPLIES_VARIATIONS_SUCCESS, items);
export const getSavedRepliesVariationsFailureAction = () =>
  action(GET_BB_REPLIES_VARIATIONS_FAILURE);
