import queryString from "query-string";
import {
  isTiktokPlatform,
  isTwitterPlatform,
  isLinkedinPlatform,
  getNormalizedTiktokPlatformId,
} from "@utils/platform";

export const getNormalizedTiktokIdFromUrl = (queryParams: queryString.ParsedQuery<string>) => {
  return getNormalizedTiktokPlatformId(queryParams.social_platform?.toString() || "");
};

export const isRedirectFromTiktokPlatform = (queryParams: queryString.ParsedQuery<string>) => {
  const platformId = getNormalizedTiktokIdFromUrl(queryParams);
  return isTiktokPlatform(platformId);
};

export const isRedirectFromLinkedinPlatform = (queryParams: queryString.ParsedQuery<string>) => {
  return queryParams.social_platform && isLinkedinPlatform(queryParams.social_platform?.toString());
};

export const isRedirectFromTwitterPlatform = (queryParams: queryString.ParsedQuery<string>) => {
  return queryParams.social_platform && isTwitterPlatform(queryParams.social_platform?.toString());
};

export const urlCodeExists = (queryParams: queryString.ParsedQuery<string>) => {
  return queryParams.code && typeof queryParams.code === "string";
};

export const getCodeFromUrl = (queryParams: queryString.ParsedQuery<string>) => {
  return queryParams.code && typeof queryParams.code === "string" ? queryParams.code : undefined;
};
