import { ajax } from "rxjs/ajax";
import { Observable, switchMap } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { mergeMap, map, filter, catchError } from "rxjs/operators";

import { Actions, IDashboard } from "./types";
import { RootState } from "..";

import {
  postDashboardActionSuccess,
  postDashboardActionFailure,
  getDashboardCSVActionFailure,
} from "./actions";
import { GET_DASHBOARD, GET_DASHBOARD_CSV } from "./actionTypes";

import { dashboard, dashboardCSV } from "@utils/paths";
import { downloadFile } from "@utils/downloadFile";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const getDashboardDataEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_DASHBOARD)),
    mergeMap(a => {
      return ajax
        .post<{ data: IDashboard }>(
          `${dashboard}`,
          {
            ...a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => postDashboardActionSuccess(data.data)),
          catchError(e => handleError(e, postDashboardActionFailure)),
        );
    }),
  );

export const getCsvEpic = (action$: Observable<Actions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(GET_DASHBOARD_CSV)),
    switchMap(({ payload: { filters, report_type } }) =>
      ajax<string>({
        method: "POST",
        url: dashboardCSV,
        body: JSON.stringify({
          component: "explorer_agent_performance",
          report_type,
          filters,
        }),
        headers: getHeaders({
          Authorization: state$.value.auth.session.accessToken.jwtToken,
        }),
        responseType: "text",
      }).pipe(
        map(e => e.response),
        map(data => {
          downloadFile(
            `${
              report_type === "detailed"
                ? "agent-performance-detailed-last-5000-messages"
                : "agent-performance-summary"
            }.csv`,
            data,
          );
        }),
        catchError(async e => {
          handleError(e, getDashboardCSVActionFailure);
        }),
      ),
    ),
  );
