import { RootState } from "..";

export const fromDashboard = (state: RootState) => state.dashboard;

export const getDashboardSelector = (state: RootState) => fromDashboard(state).dashboard;
export const getDashboardSelectorPending = (state: RootState) =>
  fromDashboard(state).fetchingDashboard;
export const getDashboardSelectorSuccessful = (state: RootState) =>
  fromDashboard(state).fetchedDashboard;

export const overviewSafetySelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_safety;
export const overviewCareSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_care;
export const overviewSentimentSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_sentiment?.items;
export const overviewPlatformsSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_platform?.items;
export const overviewMessageTypesSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_message_types;
export const safetyBreakdownSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.safety_breakdown?.items;
export const overviewConversationsSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_conversations?.items;
export const overviewEmojisSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_top_emojis?.items;
export const overviewLanguagesSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_top_languages?.items;
export const overviewTopInfluencersSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_top_influencers?.items;
export const overviewTopKeywordsSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_top_keywords?.items;
export const overviewTopKeywordPhrasesSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.explorer_overview_top_keyword_phrases?.items;
export const overviewReviewWidgetSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.review_widgets;
export const getDashboardManagedSummarySelector = (state: RootState) =>
  fromDashboard(state).dashboard?.action_summary_stats;
export const getDashboardHiddenCommentsSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.hidden_comments;
export const hiddenBreakdownSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.hidden_breakdown?.items;
export const hiddenTimelineSelector = (state: RootState) =>
  fromDashboard(state).dashboard?.hidden_timeline?.date_histogram?.datasets;
export const getSentimentTimeline = (state: RootState) =>
  fromDashboard(state).dashboard?.sentiment_timeline;
export const getSentimentBreakdown = (state: RootState) =>
  fromDashboard(state).dashboard?.sentiment_breakdown;
