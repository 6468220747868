import React, { cloneElement, isValidElement } from "react";

import { IButtonProps, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import usePermissions from "@utils/usePermissions";

import { IButtonPermissionGuardProps } from "./ButtonPermissionGuard.type";

import s from "./ButtonPermissionGuard.module.less";

const tooltipDelay = 0.2;
const tooltipFontSize = 14;

const ButtonPermissionGuard: React.FC<IButtonPermissionGuardProps> = ({
  permission,
  message,
  children,
}) => {
  const permissions = usePermissions();
  const hasPermission = typeof permission === "string" ? !!permissions[permission] : permission;

  const disabledButton = isValidElement(children)
    ? cloneElement<IButtonProps>(children, {
        disabled: true,
        onClick: undefined,
        onChange: undefined,
      })
    : children;

  return hasPermission ? (
    <>{children}</>
  ) : message ? (
    <Tooltip
      title={message}
      mouseEnterDelay={tooltipDelay}
      overlayInnerStyle={{ fontSize: tooltipFontSize }}
    >
      <span className={s.bbCursorNotAllowed}>{disabledButton}</span>
    </Tooltip>
  ) : (
    disabledButton
  );
};

export default ButtonPermissionGuard;
