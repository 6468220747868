import React from "react";
import { useTranslation } from "react-i18next";

import CardStat from "../../../../_common/CardStat/CardStat";

import { IAggregationStatProps } from "./AggregationStat.type";
import { useTiktokEnabled } from "@utils/useTiktokEnabled";

const AggregationStat: React.FC<IAggregationStatProps> = ({ item, color, widthInfo }) => {
  const { t } = useTranslation();
  const isTiktokEnabled = useTiktokEnabled();

  if (!isTiktokEnabled && item.id === "not_applicable") {
    return null;
  }

  const tooltipDelay = 0.2;
  const prevValue = item && item.previoud_period ? item.previoud_period.count : undefined;

  const value = item.count;

  const data = item && item.histogram ? item.histogram.items : undefined;

  const lineXName = item && item.histogram ? item.histogram.reference_line_x : undefined;

  return (
    <CardStat
      name={item.name}
      value={value}
      color={color}
      lineXName={lineXName}
      previousValue={prevValue}
      tooltipDelay={tooltipDelay}
      widthInfo={widthInfo || 154}
      data={lineXName ? data : undefined}
      timelineTooltipProps={{
        title: t("components:searchPanel:cardStat:graphTooltips:timeline"),
        style: { width: 200, fontSize: 14 },
      }}
      percentageTooltipProps={{
        title: t("components:searchPanel:cardStat:graphTooltips:percentage"),
        style: { width: 285, fontSize: 14 },
      }}
    />
  );
};

export default AggregationStat;
