import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Row, Col } from "antd";
import Header from "../_common/header";
import DownloadButton from "../downloadButton";
import GenerateAIButton from "../generateAIButton";
import SentimentsCards from "./sentimentsCards/SentimentsCards";
import EngagementsCards from "./engagementsCards/EngagementsCards";
import CommentEngagementCards from "./commentEngagementCards";
import PlatformsPieChart from "./platformsPieChart/PlatformsPieChart";
import SentimentPieChart from "./sentimentPieChart/SentimentPieChart";
import UserLanguages from "./userLanguages/UserLanguages";
import TopInfluencers from "../_common/topInfluencers/TopInfluencers";
import MessageTypePieChart from "./messageTypePieChart/MessageTypePieChart"; // eslint-disable-line max-len
import MessageTypeDetailTable from "./messageTypeDetailTable/MessageTypeDetailTable"; // eslint-disable-line max-len
import MostCommentedPosts from "./mostCommentedPosts/MostCommentedPosts"; // eslint-disable-line max-len

import {
  getDashboardSelectorPending,
  getDashboardSelectorSuccessful,
  overviewSentimentSelector,
  overviewPlatformsSelector,
  overviewMessageTypesSelector,
  overviewConversationsSelector,
  overviewLanguagesSelector,
  overviewTopInfluencersSelector,
} from "@store/dashboard/selectors";
import { meSuccessfulSelector } from "@store/me/selectors";
import { filterDataSelector } from "@store/filters/selectors";

import { postDashboardAction } from "@store/dashboard/actions";

import s from "./Overview.module.less";

import { useAppFilters } from "@utils/appFilters";
import { IFilters } from "@store/filters/types";
import { useYTSelected } from "@utils/useYTselected";
import { IDashboardPayload, OverviewComponentType } from "@store/dashboard/types";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

export const Overview: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateSearchDataAndUrl } = useAppFilters();

  const [topSectionsLoading, setTopSectionsLoading] = useState(false);
  const [topSectionsLoaded, setTopSectionsLoaded] = useState(false);
  const [bottomSectionsLoading, setBottomSectionsLoading] = useState(false);

  const filters = useSelector(filterDataSelector);
  const meFetched = useSelector(meSuccessfulSelector);
  const dashboardLoading = useSelector(getDashboardSelectorPending);
  const dashboardLoaded = useSelector(getDashboardSelectorSuccessful);
  const overviewSentiments = useSelector(overviewSentimentSelector);
  const overviewPlatforms = useSelector(overviewPlatformsSelector);
  const overviewMessageType = useSelector(overviewMessageTypesSelector);
  const overviewConversations = useSelector(overviewConversationsSelector);
  const overviewLanguages = useSelector(overviewLanguagesSelector);
  const overviewTopInfluencers = useSelector(overviewTopInfluencersSelector);

  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);

  const getDashboardData = useCallback(
    (data: IDashboardPayload) => dispatch(postDashboardAction(data)),
    [dispatch],
  );

  const getTopSections = useCallback(() => {
    getDashboardData({
      filters,
      components: [
        "explorer_overview_sentiment",
        "explorer_overview_platform",
        "explorer_overview_conversations",
      ],
    });
  }, [filters, getDashboardData]);

  const getBottomSections = useCallback(() => {
    const components: OverviewComponentType[] = [
      "explorer_overview_top_languages",
      "explorer_overview_top_influencers",
      "explorer_overview_message_types",
    ];

    getDashboardData({
      filters,
      components,
    });
  }, [filters, getDashboardData]);

  useEffect(() => {
    if (meFetched && filters && !topSectionsLoading) {
      getTopSections();
      setTopSectionsLoading(true);
    }
    // eslint-disable-next-line
  }, [meFetched, filters, getTopSections]);

  useEffect(() => {
    if (dashboardLoaded && !dashboardLoading) {
      if (topSectionsLoading) {
        setTopSectionsLoading(false);
        setTopSectionsLoaded(true);
      }
      if (bottomSectionsLoading) {
        setBottomSectionsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [dashboardLoading, dashboardLoaded]);

  useEffect(() => {
    if (!topSectionsLoading && topSectionsLoaded && !bottomSectionsLoading) {
      getBottomSections();
      setBottomSectionsLoading(true);
    }
    // eslint-disable-next-line
  }, [topSectionsLoading, topSectionsLoaded]);

  const filterToSpecificItem = (item: IFilters) => {
    updateSearchDataAndUrl(item, "id");
  };

  const handleSentimentCardClick = (type: string) => {
    const newFilters = {
      ...filters,
      sentiments: [type],
    };
    filterToSpecificItem(newFilters);
    navigate("?section=listen-sentiment_details", {
      state: {
        filters: newFilters,
      },
    });
  };

  return (
    <>
      <Header>
        <GenerateAIButton />
        <Tooltip
          title={isYTFilterSelected ? t("pages:insights:ytNoDownload") : ""}
          placement="bottom"
        >
          <span>
            <DownloadButton disabled={isYTFilterSelected} />
          </span>
        </Tooltip>
      </Header>
      {filters && (
        <div className={s.bbOverviewContainer}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <SentimentsCards
                data={overviewSentiments || []}
                loading={topSectionsLoading}
                filters={filters}
                handleSentimentCardClick={handleSentimentCardClick}
              />
            </Col>
            <Col xs={24}>
              {overviewConversations?.length === 4 ? (
                <EngagementsCards data={overviewConversations || []} loading={topSectionsLoading} />
              ) : overviewConversations?.length === 2 ? (
                <CommentEngagementCards
                  data={overviewConversations || []}
                  loading={topSectionsLoading}
                />
              ) : null}
            </Col>
          </Row>

          <ReactResizeDetector
            handleWidth
            refreshMode="throttle"
            refreshOptions={{ leading: true, trailing: true }}
          >
            {(props: { width: number }) => (
              <MostCommentedPosts
                filterToSpecificItem={filterToSpecificItem}
                filters={filters}
                containerWidth={props.width}
              />
            )}
          </ReactResizeDetector>

          <Row gutter={[24, 24]} className={s.bbPieChartsWrapper} id="overview-content-section">
            <Col xs={24} xl={12}>
              <SentimentPieChart
                data={overviewSentiments || []}
                filterData={filters}
                loading={topSectionsLoading}
                filterToSpecificItem={filterToSpecificItem}
              />
            </Col>
            <Col xs={24} xl={12}>
              <PlatformsPieChart
                data={overviewPlatforms || []}
                loading={topSectionsLoading}
                filterData={filters}
                filterToSpecificItem={filterToSpecificItem}
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]} className={s.bbBottomSection}>
            <Col xs={24} xl={12}>
              <UserLanguages
                data={overviewLanguages || []}
                loading={bottomSectionsLoading}
                filterData={filters}
                filterToSpecificItem={filterToSpecificItem}
              />
            </Col>
            <Col xs={24} xl={12}>
              <TopInfluencers
                data={overviewTopInfluencers || []}
                loading={bottomSectionsLoading}
                filterData={filters}
                filterToSpecificItem={filterToSpecificItem}
              />
            </Col>

            <Col xs={24} xl={12}>
              <MessageTypePieChart
                data={overviewMessageType?.messageTypeDistribution}
                loading={bottomSectionsLoading}
                filterData={filters}
                filterToSpecificItem={filterToSpecificItem}
              />
            </Col>
            <Col xs={24} xl={12}>
              <MessageTypeDetailTable
                data={overviewMessageType?.messageTypeDetail || []}
                loading={bottomSectionsLoading}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
