import cn from "classnames/bind";
import { pdfjs } from "react-pdf";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Empty } from "antd";
import { PostInfo } from "./PostInfo";
import { PostImage } from "./PostImage";
import { PostChart } from "./PostChart";
import { Tooltip, Button, Loading } from "@bbdevcrew/bb_ui_kit_fe";
import { PostInsights } from "./PostInsights";
import { PostReactions } from "./PostReactions";
import PostTagsList from "./PostTags/PostTagsList";
import PostTagsDropdown from "./PostTags/PostTagsDropdown";

import {
  shortenedSearchesExplorerLinkSelector,
  postShortenedSearchesSuccessfulSelector,
} from "@store/shortenedSearches/selectors";
import { filterDataSelector } from "@store/filters/selectors";
import {
  postShortenedSearchesAction,
  resetShortenedSearchesAction,
} from "@store/shortenedSearches/actions";
import { deleteTagFromPostAction } from "@store/postTags/actions";

import s from "./Post.module.less";

import { getPlatformIconRound, platformIconName } from "@utils/platform";
import { IPostProps } from "./Post.type";

import { SearchIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { ExportIcon, PlusIcon } from "@assets/index";

// loading the pre-packaged version of pdfjs to initialize a worker in the browser
// needed to display any pdf's showing in the preview
// eslint-disable-next-line
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const cx = cn.bind(s);

const Post: FC<IPostProps> = ({
  post,
  bars,
  total,
  stats,
  texts,
  tooltips,
  platform,
  isLoading,
  tooltipDelay,
  showPostTags,
  maxTagListLength,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasTags = !!post?.post_tags?.length;

  const postShortenedSearchesFetched = useSelector(postShortenedSearchesSuccessfulSelector);
  const shortenedSearchesExplorerLink = useSelector(shortenedSearchesExplorerLinkSelector);
  const filters = useSelector(filterDataSelector);

  const deleteTagFromPost = (tagId: string) => {
    post?.id && dispatch(deleteTagFromPostAction({ post_tag_id: tagId, post_id: post?.id }));
  };

  useEffect(() => {
    if (postShortenedSearchesFetched) {
      window.location.href = shortenedSearchesExplorerLink;
      dispatch(resetShortenedSearchesAction());
    }
    // eslint-disable-next-line
  }, [postShortenedSearchesFetched]);

  const handleExporeInSearch = () => {
    if (!post?.id) return;

    dispatch(postShortenedSearchesAction({ filters: { ...filters, post_ids: [post.object_id] } }));
  };

  const handleNavigatePostLink = () => {
    window.open(post?.permalink, "_blank")?.focus();
  };

  return (
    <div className={s.bbPost} data-cy="social-post">
      {isLoading ? (
        <div className={s.bbPostLoader}>
          <Loading type="spinning" isLoading />
        </div>
      ) : post ? (
        <>
          <PostImage post={post} />

          <div className={s.bbPostRight}>
            <PostChart stats={stats} bars={bars} total={total} />

            {!!platform && (
              <div
                className={cx(s.bbPostPlatform, `bbPostPlatform-${platform}`)}
                data-cy="post-platform"
              >
                {getPlatformIconRound(platformIconName(platform))}
              </div>
            )}

            <PostInfo post={post} tooltips={tooltips} tooltipDelay={tooltipDelay} texts={texts} />

            <PostReactions post={post} platform={platform} />

            <PostInsights post={post} />

            {showPostTags && (
              <>
                <div className={s.bbPostLabel} data-cy="post-label">
                  {t("components:comments:post:postTagsLabel")}
                </div>
                <div className={s.bbPostTagsListingWrapper}>
                  {!!post?.post_tags.length && (
                    <PostTagsList
                      postTags={post.post_tags}
                      onDeleteTag={deleteTagFromPost}
                      tagClassName={s.bbPostTagListTag}
                      tagIconClassName={s.bbPostTagListTagIcon}
                      maxTagListLength={maxTagListLength}
                    />
                  )}
                  <PostTagsDropdown
                    postTags={post.post_tags}
                    postId={post.id}
                    trigger={
                      <Button
                        _size="sm"
                        _type="secondary"
                        className={cx(s.bbPostTagsDropdownTrigger, {
                          [s.bbPostTagsDropdownTriggerNoTags]: !hasTags,
                        })}
                      >
                        <PlusIcon />
                        {!hasTags && t("components:postTags:addBtn")}
                      </Button>
                    }
                  />
                </div>
              </>
            )}

            <div className={s.bbPostActionIcons}>
              <Tooltip placement="top" title={t("components:comments:post:tooltips:explore_link")}>
                <Button
                  _size="sm"
                  _type="primary"
                  onClick={handleExporeInSearch}
                  className={s.bbPostExploreAction}
                >
                  <span className={s.bbPostExploreActionIcon}>
                    <SearchIcon />
                  </span>
                  {t("components:comments:post:exploreLink")}
                </Button>
              </Tooltip>
              {post?.permalink && (
                <Tooltip placement="top" title={t("components:comments:post:tooltips:post_link")}>
                  <Button
                    _size="sm"
                    _type="secondary"
                    className={s.bbPostLinkAction}
                    onClick={handleNavigatePostLink}
                  >
                    <span className={s.bbPostLinkActionIcon}>
                      <ExportIcon />
                    </span>
                    {texts?.linkToPost || ""}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </>
      ) : (
        <Empty className={s.bbPostEmpty} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

export default Post;
