import { FormProps } from "rc-field-form";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useMemo, useState } from "react";

import { FormInstance } from "antd";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";
import { Icon, Input, Tooltip, IObjectOption, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";

import {
  HelpIcon,
  DropdownMenuPlacementType,
  handleMultiselectState,
} from "@bbdevcrew/bb_ui_kit_fe";
import { sanitizeUnderScoreAndUppercase } from "../helper";
import { IMultiSelectFilterOption } from "@store/me/types";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

interface IMessageStatusFiltersProps extends Omit<FormProps, "onChange"> {
  placeholder?: string;
  InputVariation?: typeof Input;
  dropdownPlacement?: DropdownMenuPlacementType;
  dropdownMatchSelectWidth?: boolean;
  customprops?: { form?: FormInstance };
  onChange?: (messageTypes?: string[]) => void;
}

export const MessageStatusFilters: FC<IMessageStatusFiltersProps> = ({
  placeholder,
  customprops,
  dropdownPlacement,
  dropdownMatchSelectWidth,
  InputVariation = Input,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  const [filterValues, setFilterValues] = useState<string[]>([]);

  const optionsFormatted = useMemo(() => {
    const options = [
      {
        id: "archived",
        label: t("components:filters:message_statuses:options:archived"),
        description: "",
        selected: false,
      },
      {
        id: "starred",
        label: t("components:filters:message_statuses:options:starred"),
        description: "",
        selected: false,
      },
      {
        id: "replied",
        label: t("components:filters:message_statuses:options:replied"),
        description: "",
        selected: false,
        items: [
          {
            id: "replied_by_bb",
            label: t("components:filters:message_statuses:options:replied_by_bb"),
            description: "",
            selected: false,
          },
          {
            id: "replied_in_platform",
            label: t("components:filters:message_statuses:options:replied_in_platform"),
            description: "",
            selected: false,
          },
        ],
      },
    ];

    const messageStatusOption = ({
      id,
      label,
      items,
      description,
    }: IMultiSelectFilterOption): IObjectOption => ({
      id: id,
      label: label,
      options: items?.map(messageStatusOption),
      iconPosition: "right",
      icon: description && (
        <>
          <Tooltip overlayStyle={{ zIndex: 100001 }} title={description}>
            <Icon icon={HelpIcon} _size="sm" />
          </Tooltip>
        </>
      ),
    });

    return options?.map(messageStatusOption) || [];
  }, [t]);

  const onInternalChange = (messageTypeValue: string) => {
    const newState = handleMultiselectState(optionsFormatted, filterValues, messageTypeValue);

    setFilterValues(newState);

    if (customprops?.form) {
      onChange?.(newState || undefined);
    }
  };

  useEffect(() => {
    setFilterValues(customprops?.form?.getFieldValue("message_statuses") || []);
    // eslint-disable-next-line
  }, []);

  const _onClear = () => {
    setFilterValues([]);

    if (customprops?.form) onChange?.(undefined);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (filterValues.length === 0) {
      return ClearableTrigger(tooltipProps);
    }

    const tooltipElement = filterValues.map(value => (
      <span key={value} style={{ display: "block" }}>
        {sanitizeUnderScoreAndUppercase(value)}
      </span>
    ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <SelectStyledMulti
      _size="sm"
      id={props.id}
      value={filterValues}
      options={optionsFormatted}
      onChange={onInternalChange}
      InputVariation={InputVariation}
      dropdownPlacement={dropdownPlacement}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      TriggerVariation={TriggerWithTooltip}
      data-cy={props["data-cy" as keyof typeof props]}
      excludePlaceholder={t("generic:exclude")}
      excludedPlaceholder={t("generic:excluded")}
      placeholder={placeholder || t("components:filters:message_statuses:placeholder")}
    />
  );
};
